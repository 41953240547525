import styled from 'styled-components';
import { breakpoints, pxToRem } from '@vfit/shared/themes';

export const ClickToCallCardFlow = styled.div`
  height: 100%;
`;

export const LoadingContainer = styled.div`
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    min-height: ${pxToRem(313)};
    min-width: ${pxToRem(272)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: ${pxToRem(313)};
    min-width: ${pxToRem(406)};
    padding: ${pxToRem(0, 0, 22, 0)};
  }
`;
