import { RumEvent } from '@datadog/browser-rum';

const DATADOG_CONSUMER_BEFORESEND = (event: RumEvent) => {
  // discard a RUM error if source_type include browser: chrome ext ecc
  if (event.type === 'error' && event.error.source_type.includes('browser')) {
    return false;
  }
  return true;
};

export { DATADOG_CONSUMER_BEFORESEND };
