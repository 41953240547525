import styled, { css } from 'styled-components';
import { animations, breakpoints, colors, getTypography, pxToRem } from '@vfit/shared/themes';

const fadeAnimation = css`
  &.enter {
    animation: ${animations.fadeInDown} 0.8s forwards;
  }

  &.exit {
    animation: ${animations.fadeOutUp} 0.8s forwards;
  }
`;

export const Container = styled.div`
  ${fadeAnimation}
  align-items: center;
  background-color: ${colors.$ffffff_80};
  display: flex;
  justify-content: center;
  left: 0;
  padding: ${pxToRem(20, 0)};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${pxToRem(26, 0)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: ${pxToRem(24, 0)};
  }
`;

export const Text = styled.h6`
  ${getTypography('h6.extrabold')}
  margin: 0;
`;

export const IconWrap = styled.div`
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  margin-right: ${pxToRem(8)};

  @media (min-width: ${breakpoints.tablet}) {
    height: ${pxToRem(40)};
    width: ${pxToRem(40)};
  }
`;

