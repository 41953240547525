import { useEffect, useState } from 'react';
import { ISKill, AppMobile, trackView as trackViewPage } from '@vfit/shared/data-access';
import { useVolaService } from '@vfit/shared/hooks';
import ClickToCall from './components/ClickToCallCard/clickToCall';
import { IVola } from './vola.models';
import { VolaTagging } from './vola.tagging';

const Vola = ({
  url,
  clickToCallData,
  taggingOpts,
  pageProduct,
  defaultNumber,
  cmsAppClickToCallChoice,
  handleClose,
}: IVola) => {
  const [isAppConfirmedActivation, setIsAppConfirmedActivation] = useState(!AppMobile.checkIsApp());
  const [telephoneNumber, setTelephoneNumber] = useState(defaultNumber || '');
  const [timeSlot, setTimeSlot] = useState('');
  // tmp tech
  const techoffer = localStorage.getItem('techOffer') || '';
  const urlParams = new URLSearchParams(url.split('?')?.[1]);
  const skill = (urlParams.get('SKILL') || urlParams.get('skill') || '') as unknown as ISKill;
  const desc = (urlParams.get('DESC') || urlParams.get('desc') || '') as string;
  const {
    storeCmbMutation,
    checkChannelMutation,
    checkChannelData,
    codeResponse,
    responseTitle,
    responseMessage,
    responseTransaction,
    checkChannelIsSuccess: isCMNSuccess,
    isSuccess,
    isLoading,
    isError,
    volaService,
  } = useVolaService(skill, desc, telephoneNumber, timeSlot);

  const handleOnTrackSubmit = (step: 'CMN' | 'CMB' | 'CMN:TYP' | 'CMB:TYP') => {
    switch (step) {
      case 'CMN': {
        return trackViewPage({
          channel: `${skill}`,
          desc: `${desc}`,
          ...(taggingOpts && { ...taggingOpts }),
          ...(pageProduct && {
            ...pageProduct,
            product_category: skill,
          }),
          ...(techoffer && { product_technology: techoffer }),
          ...(responseTransaction && { transaction_id: responseTransaction }),
          page_type: 'CTC CMN',
          page_name_override: `Privati:CTC:CMN`,
          ignore_base_page_name: true,
          event_name: 'journey_start',
          event_category: 'journey',
          journey_type: 'journey',
          journey_name: 'CTC',
          product_target_segment: 'consumer',
        });
      }
      case 'CMB': {
        return trackViewPage({
          channel: `${skill}`,
          desc: `${desc}`,
          ...(taggingOpts && { ...taggingOpts }),
          ...(pageProduct && {
            ...pageProduct,
            product_category: skill,
          }),
          ...(techoffer && { product_technology: techoffer }),
          ...(responseTransaction && { transaction_id: responseTransaction }),
          page_type: 'CTC CMB',
          page_name_override: `Privati:CTC:CMB`,
          ignore_base_page_name: true,
          event_name: 'journey_start',
          event_category: 'journey',
          journey_type: 'journey',
          journey_name: 'CTC',
          product_target_segment: 'consumer',
        });
      }
      case 'CMN:TYP': {
        return trackViewPage({
          channel: `${skill}`,
          desc: `${desc}`,
          ...(taggingOpts && { ...taggingOpts }),
          ...(pageProduct && {
            ...pageProduct,
            product_category: skill,
          }),
          ...(techoffer && { product_technology: techoffer }),
          ...(responseTransaction && { transaction_id: responseTransaction }),
          page_type: 'CTC CMB TYP',
          page_name_override: `Privati:CTC:CMN:TYP`,
          ignore_base_page_name: true,
          event_name: 'journey_end',
          event_category: 'journey',
          journey_type: 'journey',
          journey_name: 'CTC',
          product_target_segment: 'consumer',
        });
      }
      case 'CMB:TYP': {
        return trackViewPage({
          channel: `${skill}`,
          desc: `${desc}`,
          ...(taggingOpts && { ...taggingOpts }),
          ...(pageProduct && {
            ...pageProduct,
            product_category: skill,
          }),
          ...(techoffer && { product_technology: techoffer }),
          ...(responseTransaction && { transaction_id: responseTransaction }),
          page_type: 'CTC CMB TYP',
          page_name_override: `Privati:CTC:CMB:TYP`,
          ignore_base_page_name: true,
          event_name: 'journey_end',
          event_category: 'journey',
          journey_type: 'journey',
          journey_name: 'CTC',
          product_target_segment: 'consumer',
        });
      }
      default:
        return null;
    }
  };

  const handleAppConfirmActivation = () => {
    setIsAppConfirmedActivation(true);
    storeCmbMutation({
      id_channel: skill,
      desc,
      telephone_number: telephoneNumber,
      contact_time: '9.00-13.00',
    });
  };

  useEffect(() => {
    if (!codeResponse && !isCMNSuccess && !isLoading && !!skill && !!desc) {
      if (!AppMobile.checkIsApp() || (AppMobile.checkIsApp() && isAppConfirmedActivation))
        checkChannelMutation({ id_channel: skill, desc });
    }
  }, []);

  return (
    <>
      {!defaultNumber && <VolaTagging />}
      <ClickToCall
        contactTimes={checkChannelData?.contact_times || []}
        channel={skill}
        desc={desc}
        volaService={defaultNumber ? 'cmn' : volaService}
        clickToCallData={clickToCallData}
        setTimeSlot={setTimeSlot}
        setTelephoneNumber={setTelephoneNumber}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        errorTitle={responseTitle}
        errorMessage={responseMessage}
        codeResponse={codeResponse || ''}
        onTrackSubmit={handleOnTrackSubmit}
        defaultNumber={defaultNumber}
        handleClose={handleClose}
        onAppConfirmActivation={handleAppConfirmActivation}
        isAppConfirmedActivation={isAppConfirmedActivation}
        cmsAppClickToCallChoice={cmsAppClickToCallChoice}
      />
    </>
  );
};

export default Vola;
