import { useFormContext } from 'react-hook-form';
import * as S from '../../deactivationPlatform.style';
import { CustomRadioProps } from '../../deactivationPlatform.models';

export const CustomRadio = ({
  name,
  options,
  handleChange,
  checkedIndex,
  ...props
}: CustomRadioProps) => {
  const { register } = useFormContext();

  return (
    <S.StyledInputContainer>
      <S.StyledRadioGroup>
        <section>
          {options?.map(
            ({ label, url }, index: number) =>
              label &&
              url && (
                <label
                  className={`options ${checkedIndex === index ? 'checked' : ''}`}
                  key={url}
                  htmlFor={`option-${index}`}
                >
                  <input
                    {...register(name, { required: true })}
                    {...props}
                    value={url}
                    type="radio"
                    onChange={() => handleChange(index)}
                    id={`option-${index}`}
                  />
                  <span>{label}</span>
                </label>
              )
          )}
        </section>
      </S.StyledRadioGroup>
    </S.StyledInputContainer>
  );
};

