import NextImage from 'next/image';
import NextImageOld from 'next/legacy/image';
import { CSSProperties, useEffect, useState } from 'react';
import { lambdaCustomLoaderHandler, PLACEHOLDER_IMAGE } from '@vfit/shared/data-access';
import { IImage } from './image.models';
import { GradientDefaultImageContainer, GradientLambdaImageContainer } from './image.style';
import vodafoneCustomLoader from '../../../../../../vodafoneCustomLoader/vodafoneCustomLoader';

const getFileAlternativeName = (fileName: unknown) => {
  if (typeof fileName !== 'string') return '';
  // Remove file extension (e.g., .jpg, .png, etc.)
  const fileNameWithoutExtension = fileName?.replace(/\.[^.]+$/, '') ?? '';

  // Replace underscores with spaces
  const fileNameCleaned = fileNameWithoutExtension?.replace(/_/g, ' ') ?? '';

  return fileNameCleaned;
};

export const Image = (props: IImage) => {
  const {
    type = 'lambda',
    onLoad,
    nameCard,
    image = '',
    imageMobile,
    alt,
    isMobile,
    style,
    gradient,
    sizes = '100vw',
    external,
    width = '100%',
    objectFit = 'contain',
    priority,
    loading,
    blurDataURL,
    placeholder,
  } = props || {
    nameCard: 'GeneralName',
    image: '',
  };
  const [srcState, setSrcState] = useState<string>(isMobile ? imageMobile || image : image);

  const imgStyle: CSSProperties = {
    objectFit: 'cover',
    objectPosition: 'center',
    width: width ?? 100,
    height: '100%',
    ...(style && { ...style }),
  };

  useEffect(() => {
    setSrcState(isMobile ? imageMobile || image : image);
  }, [isMobile]);

  const getDefaultImage = () => {
    const img = (
      <div style={{ width: 'inherit', height: 'inherit', position: 'relative' }}>
        <NextImageOld
          loader={!external ? (vodafoneCustomLoader as never) : () => srcState}
          onLoad={onLoad}
          src={srcState}
          alt={alt || getFileAlternativeName(srcState)}
          style={imgStyle}
          quality={100}
          layout="fill"
          //width={Number(width)}
          objectFit={objectFit}
          priority={priority}
          loading={loading}
          blurDataURL={blurDataURL}
          placeholder={placeholder}
          data-attribute={`imgbackground-${nameCard}`}
          onError={() => {
            setSrcState(PLACEHOLDER_IMAGE);
          }}
        />
      </div>
    );
    const containerImg = (
      <GradientDefaultImageContainer gradient={gradient}>{img}</GradientDefaultImageContainer>
    );
    if (gradient) return containerImg;
    return img;
  };

  const getLambdaImage = () => {
    const img = (
      <NextImage
        loader={!external ? lambdaCustomLoaderHandler : () => srcState}
        src={srcState}
        alt={alt || getFileAlternativeName(srcState)}
        style={imgStyle}
        sizes={sizes}
        width={0}
        height={0}
        onLoad={onLoad}
        priority={priority}
        loading={loading}
        blurDataURL={blurDataURL}
        placeholder={placeholder}
        data-attribute={`imgbackground-${nameCard}`}
        onError={() => {
          setSrcState(PLACEHOLDER_IMAGE);
        }}
      />
    );
    const containerImg = (
      <GradientLambdaImageContainer gradient={gradient}>{img}</GradientLambdaImageContainer>
    );
    if (gradient) return containerImg;
    return img;
  };

  if (type === 'lambda') return getLambdaImage();

  return getDefaultImage();
};

export default Image;

