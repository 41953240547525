import {ISvgComponent} from "../../svg.models";
import * as S from "../svg.style";

export const TrashCan : React.FC<ISvgComponent> = ({color, height, width, shrinkable, ...rest}) => (
  <S.Svg xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         color={color}
         viewBox="0 0 24 24"
         fill="none"
         shrinkable={shrinkable}
         {...rest}
  >

    <path
      d="M13.5012 18C13.5012 18.2761 13.7251 18.5 14.0012 18.5C14.2774 18.5 14.5012 18.2761 14.5012 18H13.5012ZM14.5012 9C14.5012 8.72386 14.2774 8.5 14.0012 8.5C13.7251 8.5 13.5012 8.72386 13.5012 9H14.5012ZM9.50124 18C9.50124 18.2761 9.7251 18.5 10.0012 18.5C10.2774 18.5 10.5012 18.2761 10.5012 18H9.50124ZM10.5012 9C10.5012 8.72386 10.2774 8.5 10.0012 8.5C9.7251 8.5 9.50124 8.72386 9.50124 9H10.5012ZM4.5 5C4.22386 5 4 5.22386 4 5.5C4 5.77614 4.22386 6 4.5 6V5ZM19.5 6C19.7761 6 20 5.77614 20 5.5C20 5.22386 19.7761 5 19.5 5V6ZM13 2.5V2V2.5ZM11 2.5V2V2.5ZM5.5 19.5H5H5.5ZM14.5012 18V9H13.5012V18H14.5012ZM10.5012 18V9H9.50124V18H10.5012ZM4.5 6H19.5V5H4.5V6ZM15.5 5.5V4.5H14.5V5.5H15.5ZM15.5 4.5C15.5 3.83696 15.2366 3.20107 14.7678 2.73223L14.0607 3.43934C14.342 3.72064 14.5 4.10218 14.5 4.5H15.5ZM14.7678 2.73223C14.2989 2.26339 13.663 2 13 2V3C13.3978 3 13.7794 3.15804 14.0607 3.43934L14.7678 2.73223ZM13 2H11V3H13V2ZM11 2C10.337 2 9.70107 2.26339 9.23223 2.73223L9.93934 3.43934C10.2206 3.15804 10.6022 3 11 3V2ZM9.23223 2.73223C8.76339 3.20107 8.5 3.83696 8.5 4.5H9.5C9.5 4.10218 9.65804 3.72064 9.93934 3.43934L9.23223 2.73223ZM8.5 4.5V5.5H9.5V4.5H8.5ZM18 5.5V19.5H19V5.5H18ZM18 19.5C18 19.8978 17.842 20.2794 17.5607 20.5607L18.2678 21.2678C18.7366 20.7989 19 20.163 19 19.5H18ZM17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21V22C17.163 22 17.7989 21.7366 18.2678 21.2678L17.5607 20.5607ZM16.5 21H7.5V22H16.5V21ZM7.5 21C7.10218 21 6.72064 20.842 6.43934 20.5607L5.73223 21.2678C6.20107 21.7366 6.83696 22 7.5 22V21ZM6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5H5C5 20.163 5.26339 20.7989 5.73223 21.2678L6.43934 20.5607ZM6 19.5V5.5H5V19.5H6Z"
      fill="#0D0D0D"
    />

  </S.Svg>
)
