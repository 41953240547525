import { ITrackErrorRes, TEventName, TrackingOptions } from '@vfit/shared/data-access';
import { IOptions } from '@vfit/shared/models';

export interface ErrorDetail {
  errorCode?: string;
  fullScreenExtended?: boolean;
  severity?: Severity;
  title?: string;
  subtitle?: string;
  message?: string;
  messageOnTop?: boolean;
  actionEvent?: () => void;
  secondActionEvent?: () => void;
  onClose?: () => void;
  actionText?: string;
  secondButtonText?: string;
  event?: TEventName;
  event_label?: string;
  options?: IOptions;
  opts?: TrackingOptions;
  disableTrack?: boolean;
  url?: string;
  fullscreen?: boolean;
  showIcon?: boolean;
  showLogo?: boolean;
  icon?: string;
  variant?: 'mva' | 'sunrise';
  keepOpenModalAfterAction?: boolean;
  hideTitle?: boolean;
  disableCloseModalOnClickAction?: boolean;
  isButtonSticky?: boolean;
  isDisabledDatadog?: boolean;
  trackError?: Partial<ITrackErrorRes>;
  invertButtons?: boolean;
  isModalFullContainer?: boolean;
}

export enum Severity {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
