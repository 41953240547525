import { purify } from '@vfit/shared/themes';

export const organizeClickToCallSubmitted = (
  cmsData: any,
  isCallMeNow: boolean,
  phoneNumber: string,
  timeSlot?: string
) => ({
  successSubmission: {
    headline: purify([cmsData?.clicktocall?.successSubmission?.headline || ''])?.[0] || '',
    img: purify([cmsData?.clicktocall?.successSubmission?.img || ''])?.[0] || '',
    imgMobile: purify([cmsData?.clicktocall?.successSubmission?.imgMobile || ''])?.[0] || '',
    message: isCallMeNow
      ? purify([
          cmsData?.clicktocall?.successSubmission?.messageCallMeNow?.replace(
            '$PHONE_NUMBER',
            `<b>${phoneNumber}</b>`
          ),
        ])?.[0] || ''
      : purify([
          cmsData?.clicktocall?.successSubmission?.message
            ?.replace('$PHONE_NUMBER', `<b>${phoneNumber}</b>`)
            .replace('$TIME', `<b>${timeSlot}</b>`) || '',
        ])?.[0] || '',
  },
});
