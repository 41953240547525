import { ImageAtoms } from '@vfit/shared/atoms';
import * as S from './clickToCallCardSubmitted.style';
import { organizeClickToCallSubmitted } from './clickToCallCardSubmitted.organize';
import { IClickToCallSubmitted } from './clickToCallCardSubmitted.models';

export const ClickToCallCardSubmitted = ({
  isCallMeNow,
  clickToCallData,
  phoneNumber,
  timeSlot,
}: IClickToCallSubmitted) => {
  const trimPhoneNumber = (phone: string) => `****${phone?.slice(4)}`;

  const CMSData = organizeClickToCallSubmitted(
    clickToCallData,
    isCallMeNow,
    trimPhoneNumber(phoneNumber),
    timeSlot
  );

  return (
    <S.ClickToCallCardSecondStep>
      <S.ImageWrapper>
        <ImageAtoms image={CMSData?.successSubmission?.img || ''} nameCard="celebrate" />
      </S.ImageWrapper>
      <S.Title>{CMSData?.successSubmission?.headline}</S.Title>
      <S.Descriprition dangerouslySetInnerHTML={{ __html: CMSData?.successSubmission?.message }} />
    </S.ClickToCallCardSecondStep>
  );
};
