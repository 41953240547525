import { ExitIcon } from '@vfit/shared-icons';
import { IModalProps } from '../../modal.models';
import * as S from './defaultModal.style';

export const DefaultModal: React.FC<IModalProps> = ({
  children,
  onCloseModal,
  isDetailsModal,
  withBorderRadius,
  height,
  tabletMode,
  customId,
  isDisabledClose,
  variant,
}) => (
    <S.Background>
      <S.Container
        id={customId}
        isDetailsModal={isDetailsModal}
        withBorderRadius={withBorderRadius || false}
        tabletMode={tabletMode}
        maxHeight={height}
        variant={variant}
      >
        {!isDisabledClose && (
          <div id={`${customId}-closing-icon-container`}>
            <span
              role="button"
              tabIndex={0}
              className="closing-icon"
              onClick={() => onCloseModal?.()}
              onKeyDown={() => onCloseModal?.()}
            >
              <ExitIcon />
            </span>
          </div>
        )}
        <S.ModalContent className="modalContent" height={height}>
          <S.Padder>{children}</S.Padder>
        </S.ModalContent>
      </S.Container>
    </S.Background>
  );
