import { API, errorMock } from '@vfit/shared/data-access';
import { INextError, LoggerInstance, serverlessClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { ICheckChannelPostResponse, ISKill } from './checkChannel.models';

/**
 * This method is used to aws lambda Vola Client - Check Channel api service
 * This in used in the Vola Modal component
 * Method: POST
 *
 * @returns
 */
const checkChannelService = (id_channel: ISKill, description: string) => {
  const ep = process?.env?.['NEXT_PUBLIC_ENABLE_NEW_VOLA'] === 'TRUE' ? API.VOLA : API.VOLA_CLIENT;
  return serverlessClient.post(ep, {
    id_channel,
    data: {
      description,
    },
    method: 'check_channel',
  }) as Promise<ICheckChannelPostResponse>;
};

/**
 * This method is used to instantiate a custom useMutation hook to handle the volaClientService
 * @returns
 */
export const useCheckChannelMutation = () =>
  useMutation<ICheckChannelPostResponse, INextError, { id_channel: ISKill; desc: string }>(
    ['checkChannelMutation'],
    ({ id_channel, desc }) => checkChannelService(id_channel, desc),
    {
      onSuccess: (data: ICheckChannelPostResponse) => {
        if (data) localStorage.setItem('checkChannel', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        if (error)
          LoggerInstance.error(`ERROR on checkChannel: `, errorMock('checkChannel', error));
        if (error) localStorage.setItem(`checkChannel`, JSON.stringify(error));
      },
    }
  );
