/* eslint-disable no-nested-ternary */
import {
  TechnologyCategories,
  TechnologyCategoriesAggregation,
  TechTypes,
} from '../technologyTypes';

export const getTechnology = (technology: TechTypes): string => {
  switch (technology.toUpperCase()) {
    case TechTypes.FTTH:
    case TechTypes.FIBRA_FTTH:
      return TechnologyCategories.FTTH;
    case TechTypes.MAKE:
    case TechTypes.VULA:
    case TechTypes.NGA:
    case TechTypes.FTTC:
      return TechnologyCategories.FTTC;
    case TechTypes.ULL:
    case TechTypes.ULL_SHDSL:
    case TechTypes.BS:
    case TechTypes.BS_SHDSL:
    case TechTypes.T3G4G:
    case TechTypes.ADSL:
      return TechnologyCategories.ADSL;
    case TechTypes.FWA:
    case TechTypes.FWA_INDOOR:
      return TechnologyCategories.FWAINDOOR;
    case TechTypes.FWA_OUTDOOR:
      return TechnologyCategories.FWAOUTDOOR;
    case TechTypes.FWA_OUTDOOR_5G:
      return TechnologyCategories.FWAOUTDOOR5G;
    case TechTypes.FTTH_TIM:
      return TechnologyCategories.FTTHTIM;
    case TechTypes.FTTH_C_D:
      return TechnologyCategories.FTTHCD;
    case TechTypes.FWA_INDOOR_5G:
      return TechnologyCategories.FWAINDOOR5G;
    default:
      return '';
  }
};

export const getTechnologyMerged = (technology: TechTypes) => {
  switch (technology.toUpperCase()) {
    case TechTypes.FTTH:
    case TechTypes.FIBRA_FTTH:
    case TechTypes.FTTH_TIM:
    case TechTypes.FTTH_C_D:
      return TechnologyCategories.FTTH;
    case TechTypes.MAKE:
    case TechTypes.VULA:
    case TechTypes.NGA:
    case TechTypes.FTTC:
    case TechTypes.ULL:
    case TechTypes.ULL_SHDSL:
    case TechTypes.BS:
    case TechTypes.BS_SHDSL:
    case TechTypes.T3G4G:
    case TechTypes.ADSL:
      return TechnologyCategories.FTTC;
    case TechTypes.FWA:
    case TechTypes.FWA_INDOOR:
    case TechTypes.FWA_INDOOR_5G:
      return TechnologyCategories.FWAINDOOR;
    case TechTypes.FWA_OUTDOOR:
    case TechTypes.FWA_OUTDOOR_5G:
      return TechnologyCategories.FWAOUTDOOR;
    default:
      return '';
  }
};

export const getTechnologyTechAppointment = (technology: TechTypes) => {
  switch (technology.toUpperCase()) {
    case TechTypes.FTTH:
    case TechTypes.FIBRA_FTTH:
      return TechnologyCategoriesAggregation.FTTH;
    case TechTypes.FTTH_TIM:
      return TechnologyCategoriesAggregation.FTTH_TIM;
    case TechTypes.NGA:
    case TechTypes.BS:
      return TechnologyCategoriesAggregation.NOT_DISAGGREGATED;
    case TechTypes.MAKE:
    case TechTypes.VULA:
    case TechTypes.ULL:
      return TechnologyCategoriesAggregation.DISAGGREGATED;
    case TechTypes.FWA:
    case TechTypes.FWA_INDOOR:
    case TechTypes.FWA_INDOOR_5G:
      return TechnologyCategoriesAggregation.FWA_INDOOR;
    case TechTypes.FWA_OUTDOOR:
    case TechTypes.FWA_OUTDOOR_5G:
      return TechnologyCategoriesAggregation.FWA_OUTDOOR;
    case TechTypes.FTTC:
    case TechTypes.ULL_SHDSL:
    case TechTypes.BS_SHDSL:
    case TechTypes.T3G4G:
    case TechTypes.ADSL:
      return TechnologyCategoriesAggregation.FALLBACK;
    default:
      return TechnologyCategoriesAggregation.FALLBACK;
  }
};

export const getTechnologyForCms = (technology: string) =>
  technology === 'FWAINDOOR'
    ? 'fwaIndoor'
    : technology === 'FWAOUTDOOR'
    ? 'fwaIndoor'
    : technology?.toLocaleLowerCase();
