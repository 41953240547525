import { useState } from 'react';
import { Button } from '@vfit/shared/atoms';
import { FormProvider, useForm } from 'react-hook-form';
import { IDataForm, IDeactivationPlatform } from './deactivationPlatform.models';
import * as S from './deactivationPlatform.style';
import { CustomRadio } from './components/RadioGroup/RadioGroup';

const DeactivationPlatform = ({ form, onSuccess, onClose }: IDeactivationPlatform) => {
  const [checkedIndex, setCheckedIndex] = useState(-1);

  const formMethods = useForm<IDataForm>({
    defaultValues: {
      radioInput: '',
    },
  });

  const handleChange = (index: number) => {
    formMethods.trigger('radioInput');
    setCheckedIndex(index);
  };

  const handleClose = () => {
    const cancelUrl = form?.landingmodal?.cancel?.url;
    if (cancelUrl) {
      onSuccess(cancelUrl, form?.landingmodal?.config?.target);
      return;
    }
    onClose();
  };

  const isDisabledSubmit = !formMethods?.formState?.isValid;
  const formSubmit = (data: IDataForm) => {
    onSuccess(data?.radioInput || '', form?.landingmodal?.config?.target);
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(formSubmit)}>
        {form?.landingmodal?.config?.title && (
          <S.StyledFormTextContainer>
            <h1
              dangerouslySetInnerHTML={{
                __html: `${form?.landingmodal?.config?.title}` || '',
              }}
            />
          </S.StyledFormTextContainer>
        )}
        <S.StyledSection>
          {form?.landingmodal?.input?.length > 1 && (
            <CustomRadio
              options={form?.landingmodal?.input}
              name="radioInput"
              checkedIndex={checkedIndex}
              handleChange={handleChange}
            />
          )}
        </S.StyledSection>
        <S.StyledContainerButton>
          <Button type="submit" disabled={isDisabledSubmit}>
            {form?.landingmodal?.submit?.label || ''}
          </Button>
        </S.StyledContainerButton>
        <S.StyledContainerButton>
          {form?.landingmodal?.cancel?.label && (
            <Button variant="secondary" onClick={() => handleClose()}>
              {form?.landingmodal?.cancel?.label || ''}
            </Button>
          )}
        </S.StyledContainerButton>
      </form>
    </FormProvider>
  );
};

export default DeactivationPlatform;

