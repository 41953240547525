import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  font-weight: 400;
  margin: ${pxToRem(0, 0, 32, 0)};
`;

export const Description = styled.p`
  padding-right: ${pxToRem(3)};
  margin-bottom: ${pxToRem(24)};
  font-weight: 400;
  ${pxToCssFont(18, 24)};

  @media (min-width: ${breakpoints.desktop}) {
    margin: ${pxToRem(0, 0, 40, 0)};
  }
`;

export const ClickToCall = styled.div`
  padding-right: 44px;
  padding-left: 44px;

  section {
    @media (min-width: ${breakpoints.tablet}) {
      margin: 0 auto;
      padding: ${pxToRem(0, 4, 0, 0)};
      width: ${pxToRem(272)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > button {
        margin: 0 auto;
        width: ${pxToRem(272)};
      }
    }
  }
`;

export const InputsBox = styled.div<{ callMeBack?: boolean }>`
  padding: ${pxToRem(0, 0, 12, 0)};

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${pxToRem(0, 0, 16, 0)};
    & > div {
      // min-width: ${pxToRem(272)};
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    justify-content: ${(p) => (p.callMeBack ? 'space-between' : 'center')};
    gap: ${pxToRem(32)};
    padding: ${pxToRem(0, 0, 22, 0)};

    & > div {
      // min-width: ${pxToRem(406)};
      width: 100%;
    }
  }
`;

export const StyledValue = styled.span`
  color: ${colors.$e60000};
  font-weight: 700;
`;

export const FooterNote = styled.h6`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(12, 16)};
  margin: ${pxToRem(16, 0, 0)};

  @media (min-width: ${breakpoints.tablet}) {
    margin: ${pxToRem(20, 0, 0)};
    text-align: center;
  }
`;

export const LoadingContainer = styled.div`
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    min-height: ${pxToRem(313)};
    min-width: ${pxToRem(272)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: ${pxToRem(313)};
    min-width: ${pxToRem(406)};
    padding: ${pxToRem(0, 0, 22, 0)};
  }
`;
