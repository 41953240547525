import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useOperatorSetting() {
  const router = useRouter();
  useEffect(() => {
    const { operator } = router.query;
    if (typeof operator === 'string') localStorage.setItem('operator', operator);
  }, [router]);
  return null;
}

