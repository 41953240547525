import { API, INextError, LoggerInstance, fwvClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import {
  ICustomerEligibilityResponse,
  ICustomerEligibility,
} from './checkCustomerEligibility.models';

const customerEligibilityService = ({ token, startFlow }: ICustomerEligibility) =>
  fwvClient.post(API.CUSTOMER_ELIGIBILITY, {
    t: token,
    ...(startFlow && { startFlow }),
  }) as Promise<ICustomerEligibilityResponse>;

export const useCustomerEligibility = () =>
  useMutation(
    ['checkCustomerEligibility'],
    (data: ICustomerEligibility) => customerEligibilityService(data),
    {
      onSuccess: (data: ICustomerEligibilityResponse) => {
        LoggerInstance.debug(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.debug(error);
      },
    }
  );
