import { setTrackView } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { getUserInfo } from './vola.utils';

export const VolaTagging = () => {
  const { trackView } = useTracking({
    event: ['starting_vola'],
    visitorTrackingOpts: getUserInfo(),
    disableInitialTrack: true,
  });
  setTrackView(trackView);
  return null;
};
