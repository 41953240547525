import { getItemCookie } from '@vfit/shared/data-access';

interface IOptanonGroups {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
}

export const isOptanonCookieAlertClosed = (): boolean =>
  Boolean(getItemCookie('OptanonAlertBoxClosed'));

export const retrieveOptanonCookieSettings = (): IOptanonGroups | null => {
  const optanonCookie = getOptanonConsentCookie();

  if (!optanonCookie || optanonCookie.trim() === '') {
    return null;
  }

  const groupsSegmentMatch = optanonCookie.match(/groups=[^&]*/);
  if (!groupsSegmentMatch) {
    return null;
  }

  const groupsData = groupsSegmentMatch[0]?.substring(7);
  if (!groupsData) {
    return null;
  }

  const pairs = groupsData.split(',');
  const groups = pairs.reduce((obj, pair) => {
    const [key, value] = pair.split(':');
    if (!key || value === undefined) {
      return obj;
    }
    return {
      ...obj,
      [key.trim()]: value.trim() === '1',
    };
  }, {});

  return groups;
};

export const isAcceptedAllCookies = () => {
  const groups = retrieveOptanonCookieSettings();
  return Object.values(groups).every((value) => value === true);
};

const getOptanonConsentCookie = (): any => {
  const regex = /OptanonConsent=([^;]+)(;|$)/;
  const match = document.cookie.match(regex);
  return match ? decodeURIComponent(match[1]) : null;
};

export const isAcceptedCookieLawGroup = (group: string): boolean => {
  if (process.env['NODE_ENV'] === 'development') return true;
  const isAlertClosed = isOptanonCookieAlertClosed();
  const groups = retrieveOptanonCookieSettings();
  if (groups === null) return false;
  return isAlertClosed && groups[group];
};