import React, { useEffect, useState } from 'react';
import { ITarget } from '@vfit/shared/models';
import { IDeactivationModalDetail } from './interfaces';
import VfModal from '../VfModal/vfModal';
import DeactivationPlatform from '../deactivationPlatform/deactivationPlatform';

export const ModalDeactivationWrapper = () => {
  const initialDeactivation: IDeactivationModalDetail = {};

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<IDeactivationModalDetail>(initialDeactivation);

  const actionDeactivation = (event) => {
    const { detail } = event as CustomEvent as { detail: IDeactivationModalDetail };
    setModalDetail(detail || modalDetail);
    setShowModal(true);
  };

  const resetModalStatus = () => {
    setModalDetail(initialDeactivation);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    resetModalStatus();
  };
  const handleSuccess = (url: string, target: ITarget) => {
    const urlTarget = target === 'self' ? '_self' : '_blank';
    window.open(url, urlTarget);
    resetModalStatus();
  };

  const handleClose = () => {
    handleCloseModal();
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('deactivateModalNotificationEvent', actionDeactivation);
    }
    return () => document.removeEventListener('deactivateModalNotificationEvent', actionDeactivation);
  }, []);

  return (
    <VfModal
      wrapperId={modalDetail.id}
      height={694}
      isOpen={showModal}
      handleClose={handleCloseModal}
    >
      <DeactivationPlatform
        form={modalDetail.form!}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    </VfModal>
  );
};

