import React, { useContext, useEffect, useState } from 'react';
import Script from 'next/script';
import handleEnv from '../handleEnv';

const isDevLocal = process.env['NODE_ENV'] === 'development';
const isBS = process.env['NEXT_PUBLIC_DXL_ENV'] === 'bs';
const env = (isDevLocal && 'dev') || (isBS && 'bs') || 'prod';
const tealiumEnv = (env === 'bs' && 'qa') || (env === 'dev' && 'qa') || 'prod';

declare global {
  interface Window {
    OptanonWrapper?: () => void;
    utag_cfg_ovrd?: {
      noview: boolean;
    };
    utag_use_wa_gc?: boolean;
    errorClass: any;
  }
}

interface ITrackingContextProvider {
  children: React.ReactNode;
}

export type ITrackingContext = {
  isReady: boolean;
  isReadyUtagSync: boolean;
  isReadyUtag: boolean;
};

export const TrackingContext = React.createContext<ITrackingContext>({
  isReady: false,
  isReadyUtagSync: false,
  isReadyUtag: false,
});

export const TrackingContextProvider = ({
  children,
  project,
}: ITrackingContextProvider & { project?: string }) => {
  const [isReadyUtagSync, setIsReadyUtagSync] = useState<boolean>(false);
  const [isReadyUtag, setIsReadyUtag] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const tealiumPath = project == 'business' ? 'it-web-business-udl' : 'it-web-consumer-udl';
  const utagUrl =
    handleEnv('NEXT_PUBLIC_UTAG_BASE_URL') ||
    `https://tags.tiqcdn.com/utag/vodafone/${tealiumPath}/${tealiumEnv}`;

  useEffect(() => {
    if (isReadyUtag && isReadyUtagSync) {
      setIsReady(true);
    }
  }, [isReadyUtag, isReadyUtagSync]);

  return (
    <TrackingContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        isReady,
        isReadyUtagSync,
        isReadyUtag,
      }}
    >
      <>
        <Script id="show-banner" strategy="afterInteractive">
          {`window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}; window.utag_cfg_ovrd.noview = true; window.utag_data = window.utag_data || {};`}
        </Script>
        <Script
          strategy="afterInteractive"
          defer
          src={`${utagUrl}/utag.js`}
          onLoad={() => setIsReadyUtag(true)}
        />
        <Script
          id="wa-tealium-script"
          strategy="afterInteractive"
          async
          defer
          src={`${utagUrl}/utag.sync.js`}
          onLoad={() => setIsReadyUtagSync(true)}
        />
        {children}
      </>
    </TrackingContext.Provider>
  );
};

export const useTrackingProvider = () => useContext(TrackingContext);
