import { API, INextError, LoggerInstance, fwvClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { IPostCustomerEligibilityResponse, IPostCustomerEligibility } from './confirmOrder.models';

const postCustomerEligibilityService = ({ t, idOrder }: IPostCustomerEligibility) =>
  fwvClient.post(API.POST_CUSTOMER_ELIGIBILITY, {
    t,
    idOrder,
  }) as Promise<IPostCustomerEligibilityResponse>;

export const usePostCustomerEligibility = () =>
  useMutation(
    ['confirmOrder'],
    (data: IPostCustomerEligibility) => postCustomerEligibilityService(data),
    {
      onSuccess: (data: IPostCustomerEligibilityResponse) => {
        LoggerInstance.debug(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.debug(error);
      },
    }
  );
