'use client';
import { useCallback, useEffect, useState } from 'react';
import { AppMobile, ITrackView, LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { FullScreenModal } from '@vfit/shared/atoms';
import { ErrorDetail, Severity } from './interfaces';
import ErrorGeneric from '../ErrorGeneric/errorGeneric';
import { ErrorGenericV2 } from '../ErrorGenericV2';
import GenericDetail from '../GenericDetail/genericDetail';
import VfModal from '../VfModal/vfModal';

export const ModalErrorWrapper = () => {
  const { trackView } = useTracking({
    event: ['starting_error_tracking'],
    disableInitialTrack: true,
  });

  const initialDetail: ErrorDetail = {
    errorCode: '',
    message: 'Si è verificato un errore.',
    title: 'Ops',
    onClose: () => {
      LoggerInstance.info('ModalErrorWrapper:modalDetail:onClose');
    },
    actionEvent: () => {
      LoggerInstance.info('ModalErrorWrapper:modalDetail:actionEvent');
    },
    secondActionEvent: () => {
      LoggerInstance.info('ModalErrorWrapper:modalDetail:secondActionEvent');
    },
    actionText: '',
    secondButtonText: '',
    severity: Severity.MEDIUM,
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<ErrorDetail>(initialDetail);

  const trackError = async (detail: ErrorDetail) => {
    if (!detail.disableTrack) {
      let trackObj: ITrackView = {
        event_name: 'page_error',
        event_category: 'error',
        page_error: `${detail.errorCode || ''}_${detail.title}_${detail.message}`,
        page_error_code: detail.errorCode,
        page_type: 'error page',
        ...(detail.opts && {
          ...detail.opts,
        }),
      };
      if (detail.trackError) {
        trackObj = {
          ...detail.trackError,
          event_name: detail?.trackError?.event_name || '',
          ...(detail.opts && {
            ...detail.opts,
          }),
        };
      }
      trackView(trackObj);
    }

    const datadogRum = await import('@datadog/browser-rum').then((lib) => lib.datadogRum);

    if (
      !detail?.isDisabledDatadog &&
      detail?.trackError &&
      Object?.keys(detail?.trackError)?.length > 0
    ) {
      const isCartOrOrder =
        window?.location?.href?.includes('shopping-cart') ||
        window?.location?.href?.includes('checkOrder');
      const dtType = detail?.trackError?.otherInfo?.dtType || '';
      datadogRum.addError(detail.trackError, {
        location: window?.location?.href,
        severity: 'error',
        ...(detail?.trackError?.isBlocking && { isBlocking: detail?.trackError?.isBlocking }),
        ...(detail?.trackError?.cart_id && { cart_id: detail?.trackError?.cart_id }),
        ...(detail?.trackError?.transaction_id && {
          transaction_id: detail?.trackError?.transaction_id,
        }),
        ...(detail?.trackError?.flow && { flow: detail?.trackError?.flow }),
        ...(detail?.trackError?.err_backendErrorMessage && {
          err_backendErrorMessage: detail?.trackError?.err_backendErrorMessage,
        }),
        ...(detail?.trackError?.user_type && { user_type: detail?.trackError?.user_type }),
        ...(detail?.trackError?.service_name && { service_name: detail?.trackError?.service_name }),
        ...(detail?.trackError?.status && { status: detail?.trackError?.status }),
        ...(detail?.trackError?.type && { type: detail?.trackError?.type }),
        ...(detail?.trackError?.slug && { slug: detail?.trackError?.slug }),
        ...(detail?.trackError?.offerId && { offerId: detail?.trackError?.offerId }),
        ...(detail?.trackError?.step && { step: detail?.trackError?.step }),
        ...(detail?.trackError?.otherInfo && { ...detail?.trackError?.otherInfo }),
        ...((isCartOrOrder || dtType) && { type: isCartOrOrder ? 'CBU_IBUY' : dtType || '' }),
      });
    }
  };

  const onError = (event) => {
    const { detail } = event as CustomEvent as { detail: ErrorDetail };
    trackError(detail);
    if (!detail.options) {
      detail.options = {
        textAlign: 'left',
        size: 18,
        lineHeight: 24,
      };
    }
    setModalDetail(detail || modalDetail);
    setShowModal(!showModal);
  };

  const resetModalStatus = () => {
    setModalDetail(initialDetail);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    if (!modalDetail?.disableCloseModalOnClickAction) resetModalStatus();
  };

  const handleActionModal = () => {
    if (modalDetail.actionEvent) modalDetail.actionEvent();
    if (!modalDetail?.disableCloseModalOnClickAction) resetModalStatus();
  };

  const handleSecondActionModal = () => {
    if (modalDetail.secondActionEvent) modalDetail.secondActionEvent();
    if (!modalDetail?.disableCloseModalOnClickAction) resetModalStatus();
  };

  const handleActionModalExtended = useCallback(() => {
    modalDetail?.actionEvent?.();
    if (!modalDetail?.keepOpenModalAfterAction) {
      resetModalStatus();
    }
  }, [modalDetail, resetModalStatus]);

  const handleSecondActionModalExtended = useCallback(() => {
    modalDetail?.secondActionEvent?.();
    if (!modalDetail?.keepOpenModalAfterAction) {
      resetModalStatus();
    }
  }, [modalDetail, resetModalStatus]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('modalErrorServiceNotificationEvent', onError);
    }
    return () => document.removeEventListener('modalErrorServiceNotificationEvent', onError);
  }, []);

  if (modalDetail?.fullScreenExtended) {
    return (
      <VfModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        isModalFullContainer
        variant="sunrise"
      >
        <ErrorGenericV2
          title={modalDetail?.title || initialDetail.title || ''}
          subtitle={modalDetail?.subtitle || ''}
          description={modalDetail?.message || initialDetail.message}
          descriptionOnTop={modalDetail?.messageOnTop}
          action={handleActionModalExtended}
          actionText={modalDetail?.actionText}
          icon={modalDetail?.icon}
          customAction={handleSecondActionModalExtended}
          customActionText={modalDetail?.secondButtonText}
          variant={modalDetail?.variant}
          hideTitle={modalDetail?.hideTitle}
          isApp={AppMobile.checkIsApp() || false}
        />
      </VfModal>
    );
  }

  if (modalDetail?.fullscreen) {
    return (
      <FullScreenModal show showIcon={!!modalDetail?.showIcon} onCloseModal={handleCloseModal}>
        <ErrorGeneric
          title={modalDetail?.title || initialDetail.title || ''}
          subtitle={modalDetail?.subtitle ?? ''}
          description={modalDetail?.message || initialDetail.message}
          action={handleCloseModal}
          actionText={modalDetail?.actionText}
          icon={modalDetail?.icon}
        />
      </FullScreenModal>
    );
  }

  return (
    <VfModal isOpen={showModal} handleClose={handleCloseModal}>
      <GenericDetail
        title={modalDetail.title || initialDetail.title || ''}
        description={modalDetail.message || initialDetail.message}
        secondButtonLabel={modalDetail.secondButtonText || ''}
        secondButtonAction={handleSecondActionModal}
        submitButtonLabel={modalDetail.actionText}
        submitButtonAction={handleActionModal}
        event={modalDetail.event}
        event_label={modalDetail.event_label}
        options={modalDetail.options}
        opts={modalDetail.opts}
        isButtonSticky={modalDetail.isButtonSticky}
        invertButtons={modalDetail.invertButtons || false}
      />
    </VfModal>
  );
};

