import { ImageAtoms } from '@vfit/shared/atoms';
import { useToast } from './toast.hook';
import { IToast } from './toast.models';
import * as S from './toast.style';

export const Toast = ({ delay, icon, onClose, show, text }: IToast) => {
  const { animationClass, onAnimationEnd, shouldRender } = useToast({ delay, onClose, show });

  return shouldRender ? (
    <S.Container className={animationClass} onAnimationEnd={onAnimationEnd}>
      {icon && (
        <S.IconWrap>
          <ImageAtoms nameCard="wrapperIcon" image={icon} />
        </S.IconWrap>
      )}
      {text && <S.Text>{text}</S.Text>}
    </S.Container>
  ) : null;
};

