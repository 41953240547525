const convertSpecialChars = (str: string): string => {
  if (!str) return '';
  const mapChars = {
    '&amp;': '&',
    '&#61;': '=',
    '&#34;': '"',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&copy;': '©',
    '&reg;': '®',
    '&euro;': '€',
    '&pound;': '£',
    '&yen;': '¥',
    '&cent;': '¢',
  };
  return str.replace(
    /&amp;|&lt;|&#61;|&#34;|&gt;|&quot;|&#039;|&copy;|&reg;|&euro;|&pound;|&yen;|&cent;/g,
    (m) => mapChars[m]
  );
};

const organizeHtml = (html?: string) => {
  if (!html) return '';
  const parser = new DOMParser();
  const doc = parser?.parseFromString(html, 'text/html');
  const outerP = doc.body.querySelector('p');
  if (outerP) {
    outerP.outerHTML = outerP.innerHTML;
  }
  return convertSpecialChars(doc?.body?.innerHTML || '');
};

export { convertSpecialChars, organizeHtml };
