import { API } from '@vfit/shared/data-access';
import { INextError, LoggerInstance, serverlessClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { ISKill } from './checkChannel.models';
import { ICheckOperatorPostResponse } from './checkOperator.models';

/**
 * This method is used to aws lambda Vola Client - Check Operator api service
 * This in used in the Vola Modal component
 * Method: POST
 *
 * @returns
 */
const checkOperatorService = (
  id_channel: ISKill,
  telephone_number: string,
  description: string
) => {
  const ep = process?.env?.['NEXT_PUBLIC_ENABLE_NEW_VOLA'] === 'TRUE' ? API.VOLA : API.VOLA_CLIENT;
  return serverlessClient.post(ep, {
    id_channel,
    data: {
      telephone_number,
      description,
    },
    method: 'check_operator',
  }) as Promise<ICheckOperatorPostResponse>;
};

/**
 * This method is used to instantiate a custom useMutation hook to handle the volaClientService
 * @returns
 */
export const useCheckOperatorMutation = () =>
  useMutation<
    ICheckOperatorPostResponse,
    INextError,
    { id_channel: ISKill; telephone_number: string; desc: string }
  >(
    ['checkOperatorMutation'],

    ({ id_channel, telephone_number, desc }) =>
      checkOperatorService(id_channel, telephone_number, desc),
    {
      onSuccess: (data: ICheckOperatorPostResponse) => {
        // queryClient.setQueryData('checkOperator', data);
        localStorage.setItem('checkOperator', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR on checkOperator: `, error);
        localStorage.setItem(`checkOperator`, JSON.stringify(error));
      },
    }
  );
