import { checkWindow, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ITrackErrorRes, TEventName, VisitorTrackingOpts } from '@vfit/shared/data-access';
import crypto from 'crypto-es';
import { LOGGED_USER_KEYS } from './vola.models';

export const getTrackError = (
  codeResponse,
  errorDesc: string,
  errorMessage: string
): ITrackErrorRes => ({
  event_name: 'page_error' as TEventName,
  event_label_track: 'page_error',
  event_category: 'error',
  page_error: `${codeResponse}_${errorDesc}_${errorMessage}`,
  page_error_code: codeResponse || '',
  page_type: 'error page',
});

export const getUserInfo = (): VisitorTrackingOpts => {
  const { SHA256 } = crypto;
  const user = getFromLocalStorageByKey('user');
  const silentLogin: any = getFromLocalStorageByKey('silentLogin');
  const loginEnrichement: any = getFromLocalStorageByKey('loginEnriched');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerData: any = getFromLocalStorageByKey('customerData');
  const appSessionFromStorage: any = checkWindow() && localStorage.getItem('appSession');
  const appSession: any = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
  const appMsisdn: string = appSession?.value;
  const visitorLoginStatus = appMsisdn || isLoggedUser ? 'logged in' : 'logged out';

  return {
    visitor_login_type: 'web',
    ...(user?.customer?.[0].id && { visitor_customer_id: user?.customer[0].id }),
    ...(user?.taggedInfoCust?.email && {
      visitor_id_email: SHA256(user?.taggedInfoCust?.email).toString(),
    }),
    ...(user?.customer?.[0].msisdns && {
      visitor_id_asset_list: user?.customer?.[0].msisdns?.map(
        (msisdn: string) => SHA256(msisdn)?.toString() || ''
      ),
    }),
    ...(loginEnrichement &&
      loginEnrichement.items[0]?.value && {
        visitor_id_asset_active: SHA256(loginEnrichement.items[0]?.value).toString(),
      }),
    ...(appMsisdn && { visitor_id_asset_active: SHA256(appMsisdn).toString() }),
    ...(visitorLoginStatus && { visitor_login_status: visitorLoginStatus }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.birthDate && {
        visitor_customer_dob: customerData[0]?.owningIndividual?.birthDate,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.gender && {
        visitor_customer_gender: customerData[0]?.owningIndividual?.gender,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.city && {
        visitor_customer_location_city: customerData[0]?.billingAddress?.city,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.nation && {
        visitor_customer_location_country: customerData[0]?.owningIndividual?.nation,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.postalCode && {
        visitor_customer_location_id: customerData[0]?.billingAddress?.postalCode,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.firstName && {
        visitor_customer_name_first: customerData[0]?.owningIndividual?.firstName,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.lastName && {
        visitor_customer_name_last: customerData[0]?.owningIndividual?.lastName,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.stateOrProvince && {
        visitor_customer_region: customerData[0]?.billingAddress?.stateOrProvince,
      }),
  };
};
