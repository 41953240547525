// eslint-disable-next-line
import { INextError } from './model';

export const NEXT_ERR_TRANSACTION_ID = 'dti';

export const NEXT_LAST_TRANSACTION_ID = 'lti';

export const NEXT_ERR_MSG = 'emsg';

export const isDevLocal = () => process.env['NODE_ENV'] === 'development';

export class DxlError extends Error {
  public code: string;

  public description: string;

  public status: string;

  constructor(message: string) {
    super(message);
    const messageSplit = message.split('|_|');
    if (messageSplit.length === 3) {
      [this.code, this.description] = messageSplit;
      this.message = messageSplit[2].replace(/transactionid.*/gi, '');
      this.status = this.code;
    } else {
      this.code = 'unknown';
      this.description = message;
      this.message = 'Ops, si è verificato un errore.';
    }
  }
}

export class NextError extends Error {
  public override message: any;

  public error: INextError;

  constructor(response: any, request: any, body?: any) {
    super();
    const { url } = request;
    const { status, statusText, headers } = response;
    this.message = JSON.parse(
      JSON.stringify(
        `{requestUrl: ${url}, responseStatus: ${status}, responseStatusText: ${statusText}}`
      )
    );
    this.error = {
      url,
      status,
      statusText,
      headers,
      body,
    };
  }

  getMessage = () => this.message;

  getError = () => this.error;
}

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const errorMessages = {
  activation: {
    strong: {
      generic: {
        title: 'Operazione non consentita',
        description: "Al momento non è possibile attivare l'offerta",
      },
      charge_wait: {
        title: 'Non è possibile attivare la promozione',
        description: 'Sono gia attive alcune promozioni in attesa di ricarica.',
      },
      deactivations: {
        title: 'Non è possibile attivare la promozione',
        description: 'Sono già attive alcune promozioni che vanno disattivate. Le promozioni sono:',
      },
      activations: {
        title: 'Operazione non consentita',
        description:
          "Al momento non è possibile attivare l'offerta, è indispensabile attivare le seguenti promozioni:",
      },
      errors: {
        title: 'Informazione',
        check_act_prod_not_man:
          'Siamo spiacenti ma al momento non è possibile attivare Giga Vacanza.',
        prod_not_man: 'Non è possibile attivare il prodotto. Ci scusiamo per il disagio.',
        req_alr_ins: 'Hai già effettuato la richiesta di attivazione per questo prodotto.',
        read_only_cond:
          "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio",
        prerequirements:
          'Non è possibile proseguire con l&rsquo;operazione da te richiesta. Ci scusiamo per il disagio.',
        not_reg_incomp: 'Il prodotto è incompatibile con la tua offerta.',
        age_check_fail:
          'Il prodotto non è compatibile con il tuo profilo. Ti invitiamo a visitare la sezione Scopri nuove offerte',
        prod_alr_act: 'Il prodotto è già attivo sul numero che stai utilizzando.',
        non_comm_pre_req_prod:
          "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
        change_reas_fail:
          "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
        tariff_model_incompatibility:
          'Questo prodotto non è compatibile con la tua offerta. Ti invitiamo a visitare il Catalogo Offerte.',
        deactivation_in_progress:
          "Questo prodotto è in fase di disattivazione. Attendi l'avvenuta disattivazione e riprova più tardi.",
        prod_not_man_on_market_cluster:
          "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
        prod_not_man_on_user_profile_cluster:
          'Non è possibile proseguire con l&rsquo;operazione da te richiesta. Ci scusiamo per il disagio.',
        prod_not_man_on_object_typology_cluster:
          'Non è possibile proseguire con l&rsquo;operazione da te richiesta. Ci scusiamo per il disagio.',
        prod_not_man_on_channel_cluster:
          "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
        generic: 'Il prodotto è incompatibile con la tua offerta. ',
        check_act_promo_xmas_already_act:
          "Siamo spiacenti, hai già richiesto l'attivazione di un Vodafone Pass in regalo.",
      },
    },
    weak: {
      deactivations: { description: ' La tua nuova offerta sostituirà' },
      activations: {
        title: 'Attenzione',
        description: "Confermando l'attivazione saranno attivati:",
      },
      charge_wait: {
        description:
          'Puoi attivare l’offerta anche se il tuo credito non è sufficiente, ricordati di ricaricare per poterla utilizzare.',
      },
      errors: {
        title: 'Errore',
        description: 'Si è verificato un errore',
      },
    },
    not_credit: {
      title: 'Attenzione',
      description:
        "Il tuo credito non è sufficiente per attivare l'opzione. Ricarica online in modo semplice e veloce",
    },
    activation_params: {
      title: 'Attenzione',
      description: "Per procedere con l'attivazione inserire le informazioni:",
    },
    confirm_activation: {
      title: 'Richiesta in attivazione',
      description: "Hai richiesto l'attivazione dell'offerta @prodName@",
    },
    success_activation: {
      title: 'Offerta in attivazione',
      description: 'La richiesta è andata a buon fine.',
    },
    success_activation_mail_to_send: {
      description:
        "Attendi l'SMS di conferma entro 48 ore. Inserisci di seguito il tuo indirizzo e-mail per ricevere la conferma dell'attivazione:",
    },
    success_activation_mail_sent: {
      description:
        "Attendi l'SMS di conferma entro 48 ore. Riceverai un email all'indizzo @emailAddress@",
    },
    success_lineup_activation: {
      title: 'Offerta in attivazione',
      description: 'La richiesta è andata a buon fine. Riceverai un SMS di conferma.',
    },
    success_lineup_booked: {
      title: 'La prenotazione dell’offerta è andata a buon fine',
      description:
        'La nuova offerta sarà attiva il giorno: @renewalDate@ e se vuoi modificarla di nuovo potrai farlo entro il giorno: @allowedDate@.',
    },
    success_activation_request_nba_informative: {
      description: 'La tua richiesta é stata presa in carico.',
    },
    error_activation: {
      title: 'Attenzione',
      description: 'Siamo spiacenti, si è verificato un errore',
    },
  },
  deactivation: {
    generic: {
      title: 'Operazione non consentita',
      description: "Al momento non è possibile disattivare l'offerta",
    },
    engaged: {
      title: 'Operazione non consentita',
      description:
        'Hai gi&agrave; prenotato la disattivazione dell&rsquo;opzione. <br\\/> L&rsquo;opzione sar&agrave; disattivata il @deactivationDate@',
    },
    errors: {
      error: {
        title: 'Operazione non consentita',
        description: "Al momento non è possibile disattivare l'offerta.",
      },
      check_act_prod_not_man:
        'Siamo spiacenti ma al momento non è possibile attivare Giga Vacanza.',
      prod_not_man: '-',
      req_alr_ins: '-',
      read_only_cond: ' Per questo prodotto è già stata richiesta la disattivazione',
      tariff_model_incompatibility:
        "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
      prod_not_man_on_market_cluster: '-',
      prod_not_man_on_user_profile_cluster: '-',
      prod_not_man_on_object_typology_cluster: '-',
      prod_not_man_on_channel_cluster:
        'Non è possibile disattivare il prodotto. Ci scusiamo per il disagio.',
      generic: '-',
      deac_alr_req: '-',
      prod_not_act: '-',
      charge_reas_fail:
        "Non è possibile proseguire con l'operazione da te richiesta. Ci scusiamo per il disagio.",
      check_manag_sticky_failure_no_desc:
        "<b>Non è possibile disattivare il prodotto.</b> <br/>Non è possibile proseguire con l'operazione da te richiesta. Per disattivare il prodotto è necessario disattivare la tariffa base associata. ",
      check_manag_sticky_failure:
        "<b>Non è possibile disattivare il prodotto.</b> <br/>Non è possibile proseguire con l'operazione da te richiesta. Per disattivare il prodotto è necessario disattivare: ",
    },
    option: {
      immediate: {
        title: 'Disattiva subito',
        description: "L'opzione sarà disattivata subito dal tuo numero",
      },
      renewal: {
        title: 'Disattiva alla scadenza',
        description:
          "L'opzione rimarrà attiva sul tuo numero e sarà disattivata il giorno @deactivationDate@",
      },
      not_selected: { message: "L'accettazione è obbligatoria" },
    },
    success_deactivation_mail_to_send: {
      description:
        "Attendi l'SMS di conferma entro 48 ore. Inserisci di seguito il tuo indirizzo e-mail per ricevere la conferma della disattivazione:",
    },
    success_deactivation_mail_sent: {
      description: "Riceverai una e-mail di conferma all'indirizzo @emailAddress@",
    },
    success_deactivation: {
      title: 'Offerta in disattivazione',
      description: 'La richiesta è andata a buon fine.',
    },
    success_booked_deactivation: {
      title: 'Offerta in annullamento',
      description: 'La richiesta è andata a buon fine.',
    },
    confirm_deactivation: {
      title: 'Attenzione',
      description: 'Sei sicuro di voler disattivare @prodName@ ?',
    },
  },
  renewal: {
    checkBlockType: {
      title: 'Attenzione',
      generic:
        'Operazione non consentita. Non è possibile far ripartire la tua opzione. Ci scusiamo per il disagio.',
      activated:
        'Operazione non consentita. Non è possibile far ripartire la tua opzione. Ci scusiamo per il disagio.',
      engaged:
        "Hai già richiesto di far ripartire la tua opzione. Attendi l'SMS di conferma attivazione e utilizzala fino al prossimo rinnovo.",
      renewing:
        "Non è possibile procedere con l'operazione richiesta dato che la tua tariffa base si rinnova nelle prossime 24 ore. Attendi l'SMS di rinnovo.",
      credit:
        'Non è possibile procedere con il Riparti dato che il credito non è sufficiente. Ricarica subito e fai ripartire i tuoi giga fino al prossimo rinnovo.',
    },
    confirm_renewal: {
      title: 'Richiesta di rinnovo',
      description: 'Vuoi far ripartire @prodName@ al costo di @renewalCost@€ ?',
    },
    success_renewal: {
      title: 'Offerta in rinnovo',
      description: 'La richiesta è andata a buon fine.',
    },
    success_renewal_mail_to_send: {
      description:
        "Attendi l'SMS di conferma entro 48 ore. Inserisci di seguito il tuo indirizzo e-mail per ricevere la conferma del rinnovo:",
    },
    success_renewal_mail_sent: {
      description:
        "Attendi l'SMS di conferma entro 48 ore. Riceverai un email all'indizzo @emailAddress@",
    },
  },
  customErrors: [
    {
      code: 650,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/387_e60000.png',
      message: 'Prodotto non presente in catalogo',
    },
    {
      code: 660,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/221_e60000.png',
      message: 'Il prodotto richiesto risulta già in attivazione',
    },
    {
      code: 670,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/387_e60000.png',
      message: 'Prodotto non presente in consistenza (offerte attive)',
    },
    {
      code: 680,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/387_e60000.png',
      message: 'Prodotto non recuperato da AEM',
    },
    {
      code: 690,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/221_e60000.png',
      message: 'Prodotto in attivazione',
    },
    {
      code: 650,
      imageUrl:
        'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/00_icon_set/red/387_e60000.png',
      message: 'Prodotto non più disattivabile',
    },
    { code: 392, message: 'PIN non corretto. Riprova.' },
    { code: 310, message: 'PIN non corretto. Riprova.' },
    {
      code: 399,
      message: "E' stato inserito per 3 volte un PIN non corretto. Riprova tra 30 minuti.",
    },
    {
      code: 362,
      message:
        'Il PIN inserito è scaduto. Richiedine uno nuovo e ricorda di utilizzarlo entro 30 minuti da quando lo ricevi.',
    },
    {
      code: 500,
      message: 'Servizio momentaneamente non disponibile. Riprova più tardi.',
    },
    {
      code: 490,
      message: 'Servizio momentaneamente non disponibile. Riprova più tardi.',
    },
  ],
};
