import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  allQueryKeyArray,
  COOKIE_CART,
  deleteAllCookies,
  isAppMock,
  resetKeyListAfterSubmit,
} from '@vfit/consumer/data-access';
import { PAGES } from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';

export function useClearStorage() {
  const { pathname, asPath } = useRouter();
  const queryClient = useQueryClient();

  const noClearPages = [PAGES.SHOPPING_CART, PAGES.CHECK_ORDER, PAGES.THANK_YOU, PAGES.OFFERS];

  const checkClearStorage = () => {
    const submitOrderStorage =
      window !== undefined && !!localStorage && localStorage.getItem('submitOrder');
    const alreadySubmitterOrder =
      !!submitOrderStorage && submitOrderStorage !== 'undefined'
        ? JSON.parse(submitOrderStorage as string)
        : undefined;

    let toBeClear = true;
    noClearPages.forEach((nC) => {
      if (asPath.includes(nC)) toBeClear = false;
    });
    if (alreadySubmitterOrder && toBeClear) {
      resetData(queryClient, [...resetKeyListAfterSubmit, ...allQueryKeyArray]);
      deleteAllCookies();
    } else if (toBeClear) {
      if (isAppMock()) {
        resetData(
          queryClient,
          allQueryKeyArray.filter((a) => a !== 'appSession')
        );
      } else {
        resetData(queryClient, allQueryKeyArray);
      }
      deleteAllCookies([COOKIE_CART]);
    }
  };

  useEffect(() => {
    checkClearStorage();
  }, [pathname]);
}
