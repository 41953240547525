import React, { useState } from 'react';
import { VfModal } from '@vfit/shared/components';
import { purify } from '@vfit/shared/themes';
import { useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance, purifyTextWithLogo } from '@vfit/shared/data-access';
import { IHtmlModalDetail } from './interfaces';
import { organizeHtml } from './utils';

export const ModalHtmlWrapper = () => {
  const initialModalHtml: IHtmlModalDetail = {
    onClose: () => {LoggerInstance.info('ModalHtmlWrapper:modalDetail:onClose')},
    target: '',
    isEnabledAlwaysShown: false,
    isEnabled: false,
    timerToDisplay: 1,
    htmlDesktop: '',
    htmlTablet: '',
    htmlMobile: '',
  };
  const setModalHeight = () => 563;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<IHtmlModalDetail>(initialModalHtml);
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  if (typeof document !== 'undefined') {
    document.addEventListener('htmlModalServiceNotificationEvent', (event) => {
      const { detail } = event as CustomEvent as { detail: IHtmlModalDetail };
      const target = localStorage.getItem('m_html_t');
      if (!detail?.isEnabledAlwaysShown && target && target === detail?.target) return;
      const [detailDesktopHtml, detailTabletHtml, detailMobileHtml] = purify(
        [
          organizeHtml(detail?.htmlDesktop || modalDetail?.htmlDesktop || ''),
          organizeHtml(detail?.htmlTablet || modalDetail?.htmlTablet || ''),
          organizeHtml(detail?.htmlMobile || modalDetail?.htmlMobile || ''),
        ],
        purifyTextWithLogo
      );
      setModalDetail({
        ...(detail || modalDetail),
        htmlDesktop: detailDesktopHtml || '',
        htmlTablet: detailTabletHtml || detailDesktopHtml || '',
        htmlMobile: detailMobileHtml || detailTabletHtml || detailDesktopHtml || '',
      });
      setTimeout(
        () => {
          setShowModal(!showModal);
        },
        detail?.timerToDisplay ? detail.timerToDisplay * 1000 : 0
      );
    });
  }

  const resetModalStatus = () => {
    setModalDetail(initialModalHtml);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    resetModalStatus();
    if (modalDetail?.target) localStorage.setItem(`m_html_t`, modalDetail.target);
  };

  return (
    <VfModal
      height={setModalHeight()}
      isOpen={showModal}
      handleClose={handleCloseModal}
      isModalFullContainer
      enableCloseClickOutside
      closeColor="white"
    >
      <>
        {(isDesktop || (!isTablet && !isMobile)) && (
          <div dangerouslySetInnerHTML={{ __html: modalDetail.htmlDesktop || '' }} />
        )}
        {isTablet && <div dangerouslySetInnerHTML={{ __html: modalDetail.htmlTablet || '' }} />}
        {isMobile && <div dangerouslySetInnerHTML={{ __html: modalDetail.htmlMobile || '' }} />}
      </>
    </VfModal>
  );
};
