const PAGES = {
  CONSUMER: 'consumer',
  HUB_APP: 'hub_app',
  TPO_PAGE: 'tpo_page',
  ENRG_PAGE: 'enrg_page',
  COMMON: 'common',
  RETE_FISSA: 'casa/fibra',
  COVERAGE_TOOL: 'tool-di-copertura',
  RETE_MOBILE: 'mobile/telefonia-mobile',
  SHOPPING_CART: 'shopping-cart',
  SHOPPING_CART_FWA: 'shopping-cart/fwa', // temporary
  CHECK_ORDER: 'checkOrder',
  THANK_YOU: 'thankyou',
  HOME_PAGE: 'home-page',
  BUSINESS_HOME_PAGE: 'business-homepage',
  GRANDIAZIENDE_HOME_PAGE: 'grandiaziende-homepage',
  ERROR: 'error',
  ADV: 'adv',
  ADV_PRODUCT: 'adv/{slug}',
  RETE_FISSA_PRODUCT: 'casa/fibra/{slug}',
  RETE_MOBILE_PRODUCT: 'mobile/telefonia-mobile/{slug}',
  LANDING_CONSUMER: 'landing-consumer',
  BUSINESS_FIXED_LINE: '/fissa',
  BUSINESS_FIXED_LINE_PDP: '/fissa/{slug}',
  BUSINESS_MOBILE_LINE_PDP: '/mobile/{slug}',
  BUSINESS_DATI_LINE_PDP: '/dati/{slug}',
  BUSINESS_MOBILE_LINE: '/mobile',
  BUSINESS_DATI_LINE: '/dati',
  BUSINESS_COVERAGE_TOOL: 'tool-di-copertura-bs',
  GENERIC_ERROR: '/error/generic-error',
  SERVICE_ERROR: '/error/service-error',
  OFFER_ERROR: '/error/offer-error',
  BUSINESS_SHOPPING_CART: '/fissa/shopping-cart',
  BUSINESS_SHOPPING_CART_FWA: '/fissa/shopping-cart/fwa', // temporary
  BUSINESS_SMARTPHONE: 'prodotti/smartphone',
  BUSINESS_TABLET: 'prodotti/tablet',
  BUSINESS_SMARTPHONE_PDP: 'prodotti/smartphone/{slug}',
  BUSINESS_TABLET_PDP: 'prodotti/tablet/{slug}',
  SLOT_NOT_AVAILABLE_ERROR: '/error/slot-not-available-error',
  TIME_EXPIRED_ERROR: '/error/time-expired-error',
  SCHEDULED_APPOINTMENT_ERROR: '/error/appointment-no-longer-schedulable-error',
  TIME_EXPIRED_OPERATOR_ERROR: '/error/time-expired-operator-error',
  SCHEDULED_APPOINTMENT_OPERATOR_ERROR: '/error/appointment-no-longer-schedulable-operator-error',
  NO_AVAILABILITY_ERROR: '/error/no-availability-error',
  NO_AVAILABILITY_OPERATOR_ERROR: '/error/no-availability-operator-error',
  PAGE_NOT_FOUND: '/404',
  OFFERS: 'offers',
};

export default PAGES;
