import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin: ${pxToRem(16, 0, 12)};
  max-width: ${pxToRem(256)};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Descriprition = styled.p`
  font-size: ${pxToRem(16)};
  margin: 0;
  max-width: ${pxToRem(256)};

  b {
    color: ${colors.$e60000};
  }
`;

export const ClickToCallCardSecondStep = styled.div`
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: ${pxToRem(15)};
`;

export const ImageWrapper = styled.div`
  width: ${pxToRem(64)};
`;
