import styled, { css } from 'styled-components';
import { animations, colors, cssprops, getTypography, pxToRem, shadows } from '@vfit/shared/themes';
import { ISelect } from './select.models';

const rotateAnimation = css`
  .arrow-enter > svg {
    animation: ${animations.rotate} 0.3s forwards;
  }

  .arrow-exit > svg {
    animation: ${animations.rotateBack} 0.3s forwards;
  }
`;

const variants = {
  options: {
    animated: css<Pick<ISelect, 'error'>>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(0, 0, 5, 5)};
      border-style: solid;
      border-top: none;
      border-width: 1px;
      padding: ${pxToRem(5, 0, 0)};
      top: ${pxToRem(64)};
    `,
    'mva:animated': css`
      border-radius: ${pxToRem(6)};
      box-shadow: ${shadows.card_overlay};
      padding: 0;
      top: ${pxToRem(53 + 8)};
    `,
    'mva:static': css`
      border-radius: ${pxToRem(6)};
      box-shadow: ${shadows.card_overlay};
      padding: 0;
      top: ${pxToRem(80 + 8)};
    `,
  },
  li: {
    animated: css<{ 'aria-selected'?: boolean }>`
      ${getTypography('body1.regular')}
      color: ${({ 'aria-selected': selected }) => (!selected ? colors.$7e7e7e : colors.$ffffff)};
      height: ${pxToRem(40)};
      padding: ${pxToRem(8, 16, 8, 20)};

      &:hover {
        background-color: ${colors.$bebebe};
        color: ${colors.$262626};
      }
    `,
    'mva:animated': css<{ 'aria-selected'?: boolean }>`
      ${getTypography('body2.regular')}
      ${({ 'aria-selected': selected }) => (!selected ? '' : 'font-weight: 700;')}
      color: ${({ 'aria-selected': selected }) => (!selected ? colors.$262626 : colors.$ffffff)};
      height: ${pxToRem(50)};
      padding: ${pxToRem(16)};
      position: relative;

      &:hover {
        background-color: ${colors.$f2f2f2};
        color: ${colors.$262626};
        font-weight: 700;
      }

      &[aria-selected='false'] + li::after,
      &:hover + li::after {
        background-color: ${colors.$bebebe};
        top: 0;
        content: '';
        display: block;
        height: 1px;
        left: ${pxToRem(16)};
        position: absolute;
        width: calc(100% - ${pxToRem(32)});
      }
    `,
    'mva:static': css<{ 'aria-selected'?: boolean }>`
      ${getTypography('body3.regular')}
      ${({ 'aria-selected': selected }) => (!selected ? '' : 'font-weight: 700;')}
      color: ${({ 'aria-selected': selected }) => (!selected ? colors.$262626 : colors.$ffffff)};
      height: ${pxToRem(50)};
      padding: ${pxToRem(16)};
      position: relative;

      &:hover {
        background-color: ${colors.$f2f2f2};
        color: ${colors.$262626};
        font-weight: 700;
      }

      &[aria-selected='false'] + li::after,
      &:hover + li::after {
        background-color: ${colors.$bebebe};
        top: 0;
        content: '';
        display: block;
        height: 1px;
        left: ${pxToRem(16)};
        position: absolute;
        width: calc(100% - ${pxToRem(32)});
      }
    `,
  },
  child: {
    animated: css<{ filteredItems: string[] }>`
      ${getTypography('body1.regular')}
      color: ${colors.$7e7e7e};
    `,
    'mva:animated': css<{ filteredItems: string[] }>`
      ${getTypography('body2.regular')}
      color: ${colors.$262626};

      &::before {
        background-color: ${colors.$bebebe};
        content: '';
        display: ${({ filteredItems }) => (filteredItems.length > 0 ? 'block' : 'none')};
        height: 1px;
        left: ${pxToRem(16)};
        position: absolute;
        width: calc(100% - ${pxToRem(32)});
        top: -1px;
      }
    `,
    'mva:static': css<{ filteredItems: string[] }>`
      ${getTypography('body2.regular')}
      color: ${colors.$262626};

      &::before {
        background-color: ${colors.$bebebe};
        content: '';
        display: ${({ filteredItems }) => (filteredItems.length > 0 ? 'block' : 'none')};
        height: 1px;
        left: ${pxToRem(16)};
        position: absolute;
        width: calc(100% - ${pxToRem(32)});
        top: -1px;
      }
    `,
  },
};

export const Container = styled.div<Pick<ISelect, 'styled' | 'svg'>>`
  position: relative;
  ${rotateAnimation}
  ${cssprops.styled}
`;

export const Options = styled.ul<
  Pick<ISelect, 'error' | 'variant' | 'maxHeight' | 'dropDownPosition'>
>`
  left: 0;
  list-style: none;
  overflow: ${({ maxHeight }) => (maxHeight ? 'auto' : 'hidden')};
  position: ${({ dropDownPosition }) => dropDownPosition ?? 'absolute'};
  transform-origin: top;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 9;
  ${({ maxHeight }) => `max-height: ${maxHeight}`};
  margin: ${({ dropDownPosition }) => (dropDownPosition === 'static' ? `-16px 0px 12px 0` : '0')};

  &.enter {
    animation: ${animations.stretchInDown} 0.3s forwards;
  }

  &.exit {
    animation: ${animations.stretchOutUp} 0.3s forwards;
  }

  > * {
    transition: all 0.3s ease;
  }

  > div::-webkit-scrollbar {
    width: ${pxToRem(4)};
    height: ${pxToRem(18)};
    border-radius: ${pxToRem(20)};
  }

  > div::-webkit-scrollbar-thumb {
    background: ${colors.$bebebe};
    border-radius: ${pxToRem(20)};
  }

  > div::-webkit-scrollbar-track {
    background: ${colors.$ffffff};
  }

  ${({ variant }) => (variant ? variants.options[variant] : '')}
`;

export const Li = styled.li<Pick<ISelect, 'variant'> & { 'aria-selected'?: boolean }>`
  background-color: ${({ 'aria-selected': selected }) =>
    !selected ? colors.$ffffff : colors.$bebebe};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
  white-space: nowrap;
  ${({ variant }) => (variant ? variants.li[variant] : '')}
`;

export const Child = styled.div<Pick<ISelect, 'variant'> & { filteredItems: string[] }>`
  background-color: ${colors.$ffffff};
  padding: ${pxToRem(16)};
  position: relative;

  > a,
  > button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ variant }) => (variant ? variants.child[variant] : '')}
`;

