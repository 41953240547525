import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Wifi: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    width={width}
    height={height}
    shrinkable={shrinkable}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2.50977 9.50013C3.7569 8.25183 5.23784 7.26155 6.8679 6.5859C8.49797 5.91026 10.2452 5.5625 12.0098 5.5625C13.7743 5.5625 15.5216 5.91026 17.1516 6.5859C18.7817 7.26155 20.2626 8.25183 21.5098 9.50013M5.26099 12.5001C6.15198 11.6081 7.21005 10.9005 8.3747 10.4177C9.53935 9.93496 10.7877 9.68646 12.0485 9.68646C13.3092 9.68646 14.5576 9.93496 15.7223 10.4177C16.8869 10.9005 17.945 11.6081 18.836 12.5001M8.08478 15.4826C8.60274 14.9644 9.21774 14.5533 9.89463 14.2728C10.5715 13.9923 11.2971 13.8479 12.0298 13.8479C12.7625 13.8479 13.488 13.9923 14.1649 14.2728C14.8418 14.5533 15.4568 14.9644 15.9748 15.4826M12.5098 19.0314C12.5098 19.3075 12.2859 19.5314 12.0098 19.5314C11.7336 19.5314 11.5098 19.3075 11.5098 19.0314C11.5098 18.7552 11.7336 18.5314 12.0098 18.5314C12.2859 18.5314 12.5098 18.7552 12.5098 19.0314ZM12.3223 19.0314C12.3223 19.204 12.1824 19.3439 12.0098 19.3439C11.8372 19.3439 11.6973 19.204 11.6973 19.0314C11.6973 18.8588 11.8372 18.7189 12.0098 18.7189C12.1824 18.7189 12.3223 18.8588 12.3223 19.0314ZM12.1348 19.0314C12.1348 19.1004 12.0788 19.1564 12.0098 19.1564C11.9407 19.1564 11.8848 19.1004 11.8848 19.0314C11.8848 18.9623 11.9407 18.9064 12.0098 18.9064C12.0788 18.9064 12.1348 18.9623 12.1348 19.0314Z"
      stroke="#0D0D0D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
);

