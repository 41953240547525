export * from './h1.style';
export * from './h2.style';
export * from './h3.style';
export * from './h4.style';
export * from './h5.style';
export * from './h5V2.style';
export * from './h6.style';
export * from './body1.style';
export * from './body2.style';
export * from './body3.style';
export * from './button1.style';
export * from './button2.style';
export * from './footnote1.style';
export * from './footnote2.style';
export * from './tag.style';

