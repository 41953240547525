import * as yup from 'yup';

export const callMeBackSchema = () =>
  yup.object({
    phoneNumber: yup.string().required('Campo Obbligatorio').min(6).max(14),
    timeSlot: yup.string().required('Campo Obbligatorio'),
  });

export const callMeSchema = () =>
  yup.object({
    phoneNumber: yup.string().required('Campo Obbligatorio').min(6).max(14),
  });

