import React from 'react';
import { Button, LoadingSpinner } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import { AppMobile } from '@vfit/shared/data-access';
import { IAppClickToCallChoice } from './appClickToCallChoice.models';
import * as S from './appClickToCallChoice.style';

const AppClickToCallChoice: React.FC<IAppClickToCallChoice> = ({
  cmsAppClickToCallChoice,
  onActive,
  isLoading,
  isAppConfirmedActivation,
  onClose,
}) => {
  const { title, description, cancelLabel, activeLabel } = cmsAppClickToCallChoice || {};
  const [pTitle, pDescription] = purify([title || '', description || '']);
  const isLoadingApp = isLoading && isAppConfirmedActivation;
  return (
    <S.Container isApp={AppMobile.checkIsApp()}>
      <S.Title>{pTitle}</S.Title>
      <S.Description
        dangerouslySetInnerHTML={{
          __html: pDescription || '',
        }}
      />
      <S.ButtonContainer>
        <Button
          onClick={onActive}
          disabled={isLoadingApp}
          variant="mva:primary"
          style={{ marginBottom: 16 }}
        >
          <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
            {activeLabel || 'Conferma'}
          </span>
          {isLoadingApp && (
            <S.LoadingContainer>
              <LoadingSpinner />
            </S.LoadingContainer>
          )}
        </Button>
        <Button onClick={onClose} disabled={isLoadingApp} variant="mva:alt1">
          <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
            {cancelLabel || 'Annulla'}
          </span>
        </Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default AppClickToCallChoice;
