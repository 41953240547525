import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import { IButtonContainer, IButtonDefault, IButtonSmall, IArrowButton } from './buttonSlide.models';

export const ButtonContainer = styled.div<IButtonContainer>`
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-20px);
    }

    60% {
      transform: translateY(-10px);
    }
  }

  span {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(14, 22)}
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333;
  }

  animation: ${({ animated }) => (animated ? 'bounce 2s infinite' : '')};
`;

export const ButtonDefault = styled.button<IButtonDefault>`
  background: ${({ buttonColor, disabled, disabledButtonColor }) =>
    !disabled ? buttonColor || colors.$ffffff : disabledButtonColor || '#ccc'};
  border-radius: ${({ isApp, isModalFullContainer }) => ((isApp || isModalFullContainer) ? pxToRem(6) : pxToRem(30))};
  padding: 10.5px 15px;
  margin: 0;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  border: 1px solid ${({ borderColor }) => borderColor || colors.$ffffff};
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  :hover {
    @media (min-width: ${breakpoints.desktop}) {
      background: ${({ hoverColor, buttonColor, disabled }) =>
        disabled ? '#ccc' : hoverColor || buttonColor || colors.$ffffff};
      border: 1px solid
        ${({ hoverColor, borderColor }) =>
          hoverColor ? 'transparent' : borderColor || colors.$ffffff};

      span {
        color: ${({ hoverTextColor, labelColor }) =>
          hoverTextColor || labelColor || colors.$262626};
      }
    }
  }

  span {
    color: ${({ labelColor }) => labelColor || colors.$262626};
  }

  :active {
    background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
    border: 1px solid
      ${({ clickColor, borderColor }) =>
        clickColor ? 'transparent' : borderColor || colors.$ffffff};

    span {
      color: ${({ clickTextColor, labelColor }) => clickTextColor || labelColor || colors.$262626};
    }
  }

  :disabled {
    border: 1px solid ${({ disabledBorderColor }) => disabledBorderColor || colors.$bebebe};
    background-color: ${({ disabledButtonColor }) => disabledButtonColor || colors.$ffffff};

    span {
      color: ${({ disabledTextColor }) => disabledTextColor || colors.$bebebe};
    }

    cursor: not-allowed;
  }

  @media (hover: none) and (pointer: coarse) {
    :hover {
      background: ${({ buttonColor, disabled }) =>
        disabled ? '#ccc' : buttonColor || colors.$ffffff};
      border: 1px solid ${({ borderColor }) => borderColor || colors.$ffffff};

      span {
        color: ${({ labelColor }) => labelColor || colors.$262626};
      }
    }

    :active {
      background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
      border: 1px solid
        ${({ clickColor, borderColor }) => clickColor || borderColor || colors.$ffffff};

      span {
        color: ${({ clickTextColor, labelColor }) =>
          clickTextColor || labelColor || colors.$262626};
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 10px 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 10px 24px;
  }
`;

export const ButtonSmall = styled.button<IButtonSmall>`
  background: ${colors.$ffffff};
  width: ${({ size }) => (size ? `${size}px` : '60px')};
  height: ${({ size }) => (size ? `${size}px` : '60px')};
  border-radius: ${({ isApp }) => (isApp ? pxToRem(6) : pxToRem(44))};
  ${({ borderColor }) => (borderColor ? `border-color: ${borderColor}` : 'border: none;')};
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  background-color: ${({ buttonColor }) => buttonColor || colors.$ffffff};

  :hover {
    background: ${({ hoverColor, buttonColor }) => hoverColor || buttonColor || colors.$ffffff};
  }

  :active {
    background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
  }
`;

export const ArrowButton = styled.div<IArrowButton>`
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ arrowWidth }) => (arrowWidth ? `${arrowWidth}px` : '9px')};
    height: ${({ arrowHeigth }) => (arrowHeigth ? `${arrowHeigth}px` : '16px')};

    path {
      stroke: ${({ color }) => color};
    }
  }
`;
