import { breakpoints, colors, fonts, pxToCssFontSize } from '@vfit/shared/themes';
import styled from 'styled-components';

export const StyledFormTextContainer = styled.div<{ textAlign?: string }>`
  padding: 0 0 16px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  h2,
  h4 {
    font-weight: inherit;
  }
`;
export const StyledInputContainer = styled.div<{ isColMiddle?: boolean; isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  flex: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    flex: ${({ isColMiddle }) => (isColMiddle ? '50%' : '100%')};
  }
`;

export const StyledRadioGroup = styled.div`
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 12px;
    border: 2px solid ${colors.$ffffff};
    @media (min-width: ${breakpoints.desktop}) {
      row-gap: 16px;
    }
  }

  span {
    font-family: ${fonts.exbold};
    ${pxToCssFontSize(20)}
    font-weight: 400;
    padding-left: 3px;
  }

  .options {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    border: 1px solid ${colors.$bebebe};
    border-radius: 6px;
    padding: 16px;
    cursor: pointer;
    @media (min-width: ${breakpoints.desktop}) {
      padding: 20px 16px 20px;
    }
    span {
      vertical-align: top;
      margin-left: 5px;
    }

  }
  .checked {
    border: 2px solid ${colors.$00697c};
  }
  input[type='radio'] {
    appearance: none;
    height: 24px;
    width: 24px;
    border: 1px solid ${colors.$bebebe};
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  input[type='radio']::before {
    content: '';
    display: block;
    width: 100%;
    height: 75%;
    border-color: ${colors.$00697c};
    border-radius: 50%;
  }

  input[type='radio']:checked::before {
    background: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }

  input[type='radio']:checked {
    border-color: ${colors.$00697c};
    align-items: center;
    display: flex;
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const StyledContainerButton = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
`;

