import { useEffect } from 'react';
import { useAnimation } from '@vfit/shared/hooks';
import { IUseToast } from './toast.models';

export const useToast = ({ delay = 10000, onClose, show }: IUseToast) => {
  const { animationClass, onAnimationEnd, shouldRender } = useAnimation({ show });

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;

    if (show) {
      timeoutID = setTimeout(() => {
        onClose?.();
      }, delay);
    }

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [delay, onClose, show]);

  return { animationClass, onAnimationEnd, shouldRender };
};

