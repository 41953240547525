import {
  IAppSession,
  IActivableProduct,
  START_FROM_APP_LOCKIN,
  DXL_NBO_CAMPAIGNS,
} from '@vfit/consumer/data-access';
import {
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
  INextError,
  LoggerInstance,
  awsConsumerClient,
} from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { UseQueryResult } from 'react-query';

/**
 * This method calls che campaigns service
 * @param payload
 * @param headers
 */
const getActivableProduct = (
  payload: { customerId: string },
  headers?: { [key: string]: string }
): Promise<unknown> =>
  awsConsumerClient.get(`${API.DXL_ACTIVE_PRODUCT_LIST}`, {
    searchParams: {
      ...payload,
    },
    headers: {
      ...(Object.keys(headers)?.length > 0 && {
        ...headers,
      }),
    },
  });

export const setDxlCampaigns = (dxlCampaignsProducts: IActivableProduct[]): IActivableProduct[] => {
  const dxlNboCampaignsFixed = dxlCampaignsProducts?.filter((campaign) =>
    campaign?.type?.includes('fixed')
  );
  if (dxlNboCampaignsFixed && dxlNboCampaignsFixed?.length > 0) {
    localStorage.setItem(START_FROM_APP_LOCKIN, 'true');
    localStorage.setItem(DXL_NBO_CAMPAIGNS, JSON.stringify(dxlNboCampaignsFixed));
    return dxlNboCampaignsFixed;
  }
  return [];
};

/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 *
 */
export const useDXLCampaigns = (customOptions?: CustomOptions) => {
  LoggerInstance.debug('********* useDXLCampaigns');
  const queryEnabler = false;
  const keysDependency =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : ['dxlCampaigns'];

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const appSession: IAppSession = getFromLocalStorageByKey('appSession');
      const customerId = appSession?.customerId || '';
      const customerUserAgent = appSession?.customerUserAgent || '';
      const installationId = appSession?.installationId || '';
      const token = appSession?.token || '';
      const customHeaders = {
        'X-Device-UserAgent': customerUserAgent,
        'X-Device-Id': installationId,
        'vf-a-appconsumerauth': token,
      };
      return getActivableProduct(
        {
          customerId,
        },
        customHeaders
      );
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: queryEnabler,
      onSuccess: (data: IActivableProduct[]) => {
        setDxlCampaigns(data);
        LoggerInstance.debug('useDXLCampaigns onSuccess : ', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.debug('UseDxlCampaigns', error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IActivableProduct[], INextError>;
};
