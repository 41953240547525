export type IVolaService = 'cmn' | 'cmb';
export type ICodeResponse =
  | '000'
  | '001'
  | '002'
  | '003'
  | '004'
  | '005'
  | '006'
  | '007'
  | '008'
  | '009'
  | '010'
  | '011'
  | '012'
  | '301'
  | '302'
  | '303'
  | '304'
  | '014'
  | '020'
  | '021';
export type ErrorDetail = {
  title: string;
  description: string;
};

export const erroreMessages: Record<string, ErrorDetail> = {
  '002': {
    title: 'Errore sul canale',
    description:
      'Ci dispiace! Stiamo riscontrando problemi sul canale. La invitiamo a riprovare più tardi.',
  },
  '003': {
    title: 'Errore sul canale',
    description:
      'Ci dispiace! Il canale risulta attualmente chiuso. La invitiamo a riprovare più tardi.',
  },
  '004': {
    title: 'Errore sul canale',
    description:
      'Ci dispiace! Il canale risulta essere in manutenzione. La invitiamo a riprovare più tardi.',
  },

  '005': {
    title: 'Errore sul canale',
    description:
      'Ci dispiace, nessun operatore è attualmente disponibile, la invitiamo a scegliere la fascia oraria di contatto di sua preferenza',
  },
  '006': {
    title: 'Errore sul canale',
    description:
      'Ci dispiace, il canale è in una fascia oraria di chiusura notturna. La invitiamo a riprovare più tardi.',
  },
  '007': {
    title: 'Errore sul canale',
    description:
      'Ti confermiamo che abbiamo già registrato la tua richiesta, sarai contattato da un nostro operatore il prima possibile.',
  },
};
