import { useEffect, useState } from 'react';
import { VfModal, Vola } from '@vfit/shared/components';
import { AppMobile, checkWindow } from '@vfit/shared/data-access';
import { IVolaModalDetail } from './interfaces';

export const ModalVolaWrapper = () => {
  const initialVola: IVolaModalDetail = {
    title: '',
    id: '',
    url: '',
    taggingOption: {},
    onClose: () => {console.log('onClose')},
  };

  const setModalHeight = () => {
    const defaultValue = 563;
    if (!checkWindow()) return defaultValue;
    const height = window?.innerHeight;
    if (!height) return defaultValue;
    return height - (height * 30) / 100;
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<IVolaModalDetail>(initialVola);

  const actionVola = (event) => {
    const { detail } = event as CustomEvent as { detail: IVolaModalDetail };
    setModalDetail(detail || modalDetail);
    setShowModal(true);
  };

  const resetModalStatus = () => {
    setModalDetail(initialVola);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    resetModalStatus();
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('volaModalNotificationEvent', actionVola);
    }
    return () => document.removeEventListener('volaModalNotificationEvent', actionVola);
  }, []);

  return (
    <VfModal
      wrapperId={modalDetail?.id || 'vola'}
      height={setModalHeight()}
      isOpen={showModal}
      handleClose={handleCloseModal}
      isModalFullContainer={AppMobile.checkIsApp()}
    >
      <Vola
        clickToCallData={modalDetail?.cmsClickToCallData}
        url={modalDetail.url}
        taggingOpts={modalDetail?.taggingOption}
        pageProduct={modalDetail?.pageProduct}
        defaultNumber={modalDetail?.defaultNumber}
        cmsAppClickToCallChoice={modalDetail?.cmsAppClickToCallChoice}
        handleClose={handleCloseModal}
      />
    </VfModal>
  );
};
