import React, { forwardRef } from 'react';
import { Arrow } from '@vfit/shared-icons';
import { IButtonSlideProps } from './buttonSlide.models';
import { ButtonContainer, ButtonSmall, ButtonDefault, ArrowButton } from './buttonSlide.style';

const ButtonSlide = forwardRef<HTMLDivElement, IButtonSlideProps>(
  (
    {
      name,
      label,
      small,
      animated,
      rotation,
      isApp,
      buttonColor,
      hoverColor,
      hoverTextColor,
      clickColor,
      clickTextColor,
      labelColor,
      iconColor,
      borderColor,
      smallSize,
      arrowWidth,
      arrowHeigth,
      disabled,
      disabledButtonColor,
      disabledBorderColor,
      disabledTextColor,
      onClick,
      isModalFullContainer = false,
    }: IButtonSlideProps,
    ref
  ) => {
    const smallButton = (): React.ReactNode => (
      <ButtonSmall
        aria-label={label || ''}
        isApp={isApp}
        size={smallSize}
        buttonColor={buttonColor}
        onClick={onClick}
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
        clickTextColor={clickTextColor}
        clickColor={clickColor}
        borderColor={borderColor}
        data-name={name}
      >
        <ArrowButton
          rotation={rotation || 0}
          color={iconColor || '#262626'}
          arrowWidth={arrowWidth}
          arrowHeigth={arrowHeigth}
        >
          <Arrow />
        </ArrowButton>
      </ButtonSmall>
    );
    const defaultButton = (): React.ReactNode => (
      <ButtonDefault
        aria-label={label || ''}
        isApp={isApp}
        buttonColor={buttonColor}
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
        clickColor={clickColor}
        clickTextColor={clickTextColor}
        labelColor={labelColor}
        borderColor={borderColor}
        disabled={disabled}
        disabledButtonColor={disabledButtonColor}
        disabledBorderColor={disabledBorderColor}
        disabledTextColor={disabledTextColor}
        onClick={onClick}
        data-name={name}
        isModalFullContainer={isModalFullContainer}
      >
        <span>{label}</span>
      </ButtonDefault>
    );
    return (
      <ButtonContainer
        animated={animated || false}
        className="button-slide-container"
        ref={ref}
      >
        {!small && defaultButton()}
        {small && smallButton()}
      </ButtonContainer>
    );
  }
);

export default ButtonSlide;



