import React, { useEffect } from 'react';
import { Portal } from '@vfit/shared/components';
import { Modal } from '@vfit/shared/atoms';
import { OverlayGlobalStyle, WrapperOverrideStyle } from './vfModal.style';
import { IVfModalProps } from './vfModal.models';

const VfModal = ({
  isOpen,
  handleClose,
  height,
  children,
  tabletMode,
  customFooter,
  wrapperId,
  isDisabledClose,
  isModalFullContainer,
  enableCloseClickOutside,
  closeColor,
  variant = 'sunrise',
}: IVfModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose?.() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  return isOpen ? (
    <Portal wrapperId="coverage-tool">
      <WrapperOverrideStyle wrapperId={wrapperId}>
        <OverlayGlobalStyle />
        <Modal
          customId={wrapperId}
          withBorderRadius
          show={isOpen}
          onCloseModal={handleClose}
          isDisabledClose={isDisabledClose}
          height={height}
          tabletMode={tabletMode}
          isModalFullContainer={isModalFullContainer}
          enableCloseClickOutside={enableCloseClickOutside}
          closeColor={closeColor}
          variant={variant}
        >
          {children}
          {customFooter && customFooter()}
        </Modal>
      </WrapperOverrideStyle>
    </Portal>
  ) : null;
};

export default VfModal;

