import { IClickToCallMeData, ICmsAppClickToCallChoice } from '@vfit/shared/models';
import { PageProduct, TrackingOptions } from '@vfit/shared/data-access';

export const LOGGED_USER_KEYS = ['existingLogin', 'newLogin', 'newEnriched'];
export interface IVola {
  url: string;
  clickToCallData: IClickToCallMeData;
  taggingOpts?: TrackingOptions;
  pageProduct?: PageProduct;
  defaultNumber?: string;
  cmsAppClickToCallChoice?: ICmsAppClickToCallChoice;
  handleClose?: () => void;
}

export type IVolaService = 'cmn' | 'cmb';
