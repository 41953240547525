import { purify } from '@vfit/shared/themes';
import { IClickToCallMeData } from '@vfit/shared/models';

export const organizeClickToCall = (cmsData: IClickToCallMeData) => ({
  title: purify([cmsData?.clicktocall?.toSubmit?.title || ''])?.[0] || '',
  description: purify([cmsData?.clicktocall?.toSubmit?.description || ''])?.[0] || '',
  descriptionCallMeNow:
    purify([cmsData?.clicktocall?.toSubmit?.descriptionCallMeNow || ''])?.[0] || '',
  phoneNumber: purify([cmsData?.clicktocall?.toSubmit?.phoneNumber || ''])?.[0] || '',
  callMeBack: purify([cmsData?.clicktocall?.toSubmit?.callMeBack || ''])?.[0] || '',
  contactTime: purify([cmsData?.clicktocall?.toSubmit?.contactTime || ''])?.[0] || '',
  buttonLabel: purify([cmsData?.clicktocall?.toSubmit?.buttonLabel || ''])?.[0] || '',
  footer: purify([cmsData?.clicktocall?.toSubmit?.footer || ''])?.[0] || '',
});

