import type { SVGProps } from 'react';
import { memo } from 'react';

const ExitIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="0.813rem"
      height="0.813rem"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{'Exit Icon'}</title>
      <path
        d="M0.5 13.5L13.5 0.5M13.5 13.5L0.5 0.5"
        stroke={props.stroke ? props.stroke : '#333'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(ExitIcon);
