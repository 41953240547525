export enum ErrorCodes {
  FISCAL_CODE_ERROR = '100',
  FISCAL_CODE_UNDERAGE = '101',
  FIND_CALLER_ERROR = '102',
  CAMPAINGS_ERROR = 'CAMPAGNS_ERROR',
  CREATE_CUSTOMER_GENERIC_ERROR = 'GENERIC_ERROR',
  CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT = 'DUPLICATE_DOCUMENT_NUMBER',
  CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT = 'DUPLICATE_FISCAL_CODE',
  CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT = 'DUPLICATE_PHONE_NUMBER',
  CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT = 'DUPLICATE_EMAIL',
  CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT = 'DUPLICATE_CONTACT',
  DIGITAL_CRM_CODE = 'DIGITAL_CRM_CODE',
  CREATE_CUSTOMER_MISSING_NATION_ADDRESS = 'PostCreateCustomerServiceCustR7_missingMandatoryParameter_OwningIndividual.nation',
  CUSTOMER_ALREADY_PRESENT = 'ContactAlreadyExists',
  MIGRATION_CODE_ERROR = 'MIGRATION_CODE_ERROR',
  MODIFY_PHONE_NUMBER_PRODUCT_ERROR = 'modifyPhoneNumberProductQuery',
  BILLING_DELIVERY_ERROR = 'BILLING_DELIVERY_ERROR',
  BILLING_ERROR = 'BILLING_ERROR',
  SHIPPING_ERROR = 'SHIPPING_ERROR',
  NUMBER_ERROR = 'NUMBER_ERROR',
  CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR = 'CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR',
  ERROR_ON_PAYMENT = 'mode=erroronpayment',
  MODE_BACK_SIA = 'mode=urlback',
  MODE_BACK_SIA_INSTANT = 'mode=urlbackinstant',
  MODE_DONE_SIA_INSTANT = 'mode=urlbackinstant',
  MODE_BACK_SIA_RECURRENT = 'mode=urlbackrecurrent',
  MODE_DONE_SIA_RECURRENT = 'mode=urldonerecurrent',
  URL_FORWARD = 'mode=urldoneonbillcapture',
  MODE_RESET_USER = 'mode=reset',
  ERROR_ON_PAYMENT_GENERIC = 'ERROR_ON_PAYMENT_GENERIC',
  ERROR_ON_PAYMENT_SOFT_KO = 'softko',
  ERROR_ON_PAYMENT_SOFT_TIMEOUT_KO = 'timeoutSoftKo',
  ERROR_ON_PAYMENT_HARD_KO = 'hardko',
  ERROR_ON_PAYMENT_HARD_TIMEOUT_KO = 'timeouthardko',
  ERROR_ON_PAYMENT_PAYMEAN_ALREADY_EXIST = 'paymeanalreadyexist',
  ERROR_ON_PAYMENT_PAYMEAN_ALREADY_EXIST_FOR_SAME_BANK_CODE = 'paymeanalreadyexistforsamebankcode',
  ERROR_ON_PAYMENT_PAYMEAN_REJECTED_OR_CANCELLED = 'mandaterejectedorcancelled',
  ERROR_ON_PAYMENT_IBAN_VALIDATION_FAILED = 'ibanvalidationfailed',
  ERROR_ON_PAYMENT_TIMEOUT_PROCESSING = 'timeoutProcessing',
  ERROR_ON_LOCKIN = 'lockinError',
  ERROR_RESERVE_EQUIPMENT = 'reserveEqError',
  ASSOCIATE_CUSTOMER_BLOCKED = 'associateCustomerBlocked',
  AUTHORIZATION_ERROR_KO = 'authorizationError',
  PAYMENT_DISTRIBUTION_ERROR = 'paymentDistributionError',
  RESERVE_EQUIPMENT_ERROR = 'reserveEqError',
  SUBMIT = 'submit',
  SUBMIT_WITH_PAYMENT = 'submitwithpayment',
  LIGHT_CREDIT_VETTING = 'lightcreditvetting',
  PAYMEAN = 'paymean',
  PATCH_CUSTOMER = 'patchCustomer',
  REFERENCE_CUSTOMER = 'customerReference',
  RETRIEVE_CUSTOMER = 'retriveCustomer',
}

export enum ICampaignErrorType {
  CAMPAIGNS,
  ACCEPT,
  GET_SHOPPING_CART,
  ASSOCIATE_CUSTOMER,
  UPDATE_PRODUCT_CHARACTERISTICS,
}

