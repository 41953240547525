import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';
import { ICSSContainer } from '../../modal.models';

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: #000000c4;
`;

export const Container = styled.div<ICSSContainer>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${(props) => (props.withBorderRadius ? '66' : '0')}px;
  border-top-left-radius: ${(props) => {
    if (!props.withBorderRadius) return '0';
    if (props.variant === 'mva') return '6px';
    return '20px';
  }};
  border-top-right-radius: ${(props) => {
    if (!props.withBorderRadius) return '0';
    if (props.variant === 'mva') return '6px';
    return '20px';
  }};
  background-color: ${colors.$ffffff};
  overflow: hidden;

  .closing-icon {
    float: right;
    margin-top: 21.5px;
    margin-right: 21.5px;

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: ${colors.$262626} !important;
    font-weight: 700 !important;

    :visited {
      color: ${colors.$262626} !important;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    ${(props) =>
      !props.tabletMode
        ? `
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 62.2%;
          border-radius: ${props.variant === 'mva' ? '6px' : '20px'};
          height: ${props.maxHeight ? '100%' : 'auto'};
          max-height: ${
            // eslint-disable-next-line no-nested-ternary
            props.maxHeight
              ? typeof props.maxHeight === 'string'
                ? props.maxHeight
                : `${props.maxHeight}px`
              : '100%'
          };
           .closing-icon {
            margin-top: 29.5px;
            margin-right: 21.5px;
          }
        `
        : `margin: 0 90px;`}
  }

  @media (min-width: ${breakpoints.desktop}) {
    left: 50%;
    top: 50%;
    border-radius: ${(props) => (props.variant === 'mva' ? '6px' : '20px')};
    width: 842px;
    transform: translate(-50%, -50%);
    ${(props) => `
            height: ${props.maxHeight ? '100%' : 'auto'};
            max-height: ${
              // eslint-disable-next-line no-nested-ternary
              props.maxHeight
                ? typeof props.maxHeight === 'string'
                  ? props.maxHeight
                  : `${props.maxHeight}px`
                : '100%'
            };
        `}

    .closing-icon {
      margin-top: 29.5px;
      margin-right: 29.5px;
    }
  }
`;

export const ModalContent = styled.div<{
  height: string | number | undefined;
  containerHeight?: string;
}>`
  height: calc(100% - 66px);
  margin: 48px 16px 32px 44px;
  overflow: auto;
  scrollbar-width: 3px;

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: calc(100% - 90px);
    margin: 60px 16px 64px 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: calc(100% - 124px);
    margin: 72px 32px 64px 109px;
  }
`;

// TODO: wip FIGMA modal error styles
export const ContainerModalError = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62.2%;
  }

  .closing-icon:hover {
    cursor: pointer;
  }
`;

export const ModalErrorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #5e5e5e;
`;

export const ModalErrorContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 25px;
`;

export const ModalErrorFooter = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px;

  button {
    text-transform: uppercase;
    max-width: 300px;
  }
`;

export const Padder = styled.div`
  padding-right: 25px;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    padding-right: 21px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-right: 74px;
  }
`;

