import {
  ISKill,
  useCheckChannelMutation,
  useCheckOperatorMutation,
  useStoreCmbMutation,
} from '@vfit/shared/data-access';
import { useEffect, useState } from 'react';
import { erroreMessages, ICodeResponse, IVolaService } from './useVola.models';

export const useVolaService = (
  skill: ISKill,
  desc: string,
  telephoneNumber: string,
  timeSlot: string
) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [codeResponse, setCodeResponse] = useState<ICodeResponse | null>(null);
  const [responseTitle, setResponseTitle] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [responseTransaction, setResponseTransaction] = useState<string>('');
  const [volaService, setVolaService] = useState<IVolaService | ''>('');

  const {
    mutate: checkChannelMutation,
    isLoading: checkChannelIsLoading,
    isError: checkChannelIsError,
    error: checkChannelError,
    isSuccess: checkChannelIsSuccess,
    data: checkChannelData,
  } = useCheckChannelMutation();

  const {
    mutate: checkOperatorMutation,
    isError: checkOperatorIsError,
    error: checkOperatorError,
    isSuccess: checkOperatorIsSuccess,
    data: checkOperatorData,
  } = useCheckOperatorMutation();

  const {
    mutate: storeCmbMutation,
    isError: storeCmbIsError,
    isSuccess: storeCmbIsSuccess,
    data: storeCmbData,
  } = useStoreCmbMutation();

  useEffect(() => {
    if (!!skill && !checkChannelIsLoading) {
      setIsLoading(true);
    }
  }, []);

  /**
   * Get now hour
   * Check & Return Boolean Check if is in these time slots
   * @param contactTimes
   * @returns
   */
  const isWithinContactTimes = (contactTimes: number[][]): boolean => {
    const now = new Date().getHours();
    return contactTimes?.some(([start, end]) => now >= start && now < end);
  };

  useEffect(() => {
    if (checkChannelIsSuccess && checkChannelData?.contact_times) {
      // Formattinh the times to be comparible
      const contactTimes = checkChannelData.contact_times.map((item) =>
        item.split('-').map((time) => parseFloat(time.replace('.', ':')))
      );

      if (isWithinContactTimes(contactTimes)) {
        setIsLoading(false);
        setVolaService('cmn');
      } else {
        setIsLoading(false);
        setVolaService('cmb');
      }
    }
  }, [checkChannelIsSuccess]);

  useEffect(() => {
    if (volaService === 'cmn' && !!telephoneNumber && !checkOperatorIsSuccess) {
      checkOperatorMutation({ id_channel: skill, telephone_number: telephoneNumber, desc });
      setIsLoading(true);
    }
  }, [volaService, telephoneNumber]);

  useEffect(() => {
    if (checkOperatorIsSuccess && checkOperatorData?.code_response === '000') {
      setVolaService('cmn');
      setIsSuccess(true);
      setIsLoading(false);
      setCodeResponse(checkOperatorData?.code_response as unknown as ICodeResponse);
      setResponseMessage(checkOperatorData?.description);
      setResponseTransaction(checkOperatorData?.id_request);
    }
    if (checkOperatorIsSuccess && checkOperatorData?.code_response === '001') {
      setVolaService('cmb');
      setIsLoading(false);
      setCodeResponse(checkOperatorData?.code_response as unknown as ICodeResponse);
      setResponseMessage(checkOperatorData?.description);
      setResponseTransaction(checkOperatorData?.id_request);
    }
  }, [checkOperatorIsSuccess, checkOperatorData]);

  useEffect(() => {
    if (
      telephoneNumber &&
      timeSlot &&
      volaService === 'cmb' &&
      checkOperatorIsSuccess &&
      checkOperatorData?.code_response === '001'
    ) {
      storeCmbMutation({
        id_channel: skill,
        desc,
        telephone_number: telephoneNumber,
        contact_time: timeSlot,
      });
      setIsLoading(true);
    }
  }, [volaService, timeSlot]);

  useEffect(() => {
    if (storeCmbIsSuccess && storeCmbData?.code_response === '000') {
      setIsSuccess(true);
      setIsLoading(false);
      setCodeResponse(storeCmbData?.code_response as ICodeResponse);
      setResponseMessage(storeCmbData?.description);
      setResponseTransaction(storeCmbData?.id_request);
    }
    if (storeCmbIsSuccess && storeCmbData?.code_response === '001') {
      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setCodeResponse(storeCmbData?.code_response as ICodeResponse);
      setResponseMessage(storeCmbData?.description);
      setResponseTransaction(storeCmbData?.id_request);
    }
  }, [checkChannelIsSuccess, storeCmbIsSuccess, storeCmbData]);

  // Error Handling
  useEffect(() => {
    if (
      (checkChannelIsError || checkOperatorIsError) &&
      (!!checkChannelError || checkOperatorError)
    ) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);

      const codeRes = (checkChannelError || checkOperatorError)?.body?.code_response;
      const errorMessToDisplay = erroreMessages[codeRes?.toString() || ''];
      setCodeResponse(codeRes);
      setResponseTitle(errorMessToDisplay?.title);
      setResponseMessage(errorMessToDisplay?.description);
    }
  }, [
    checkChannelIsError,
    checkOperatorIsError,
    storeCmbIsError,
    checkChannelError,
    checkOperatorError,
  ]);

  return {
    codeResponse,
    responseTitle,
    responseMessage,
    responseTransaction,
    checkChannelMutation,
    checkChannelIsSuccess,
    checkChannelData,
    checkOperatorIsSuccess,
    storeCmbMutation,
    isLoading,
    isError,
    isSuccess,
    volaService,
  };
};
