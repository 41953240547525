export const removeCurrency = (currencyString: string): string => {
  let cleanString = currencyString.replace(/[^0-9.,]+/g, '');

  // If it contains both '.' and ',', suppose ',' is the decimal separator
  if (cleanString.includes('.') && cleanString.includes(',')) {
    cleanString = cleanString.replace(/\./g, '');
  }

  // Replace any commas with full stops to standardise the format
  cleanString = cleanString.replace(',', '.');

  // Find the last point (which will be considered as decimal separator)
  const lastDotIndex = cleanString.lastIndexOf('.');

  let integerPart = '';
  let decimalPart = '';

  if (lastDotIndex !== -1) {
    //  If there is a point, it separates the integer part from the decimal part
    integerPart = cleanString.substring(0, lastDotIndex).replace(/\D/g, '');
    decimalPart = cleanString.substring(lastDotIndex + 1).replace(/\D/g, '');
  } else {
    // If there is no point, everything is whole
    integerPart = cleanString.replace(/\D/g, '');
  }

  // Removes any leading zeros in the integer part, but retains at least ‘0’ if empty
  integerPart = integerPart.replace(/^0+/, '') || '0';

  // We only take the first two digits of the decimal part, adding zeros if necessary
  decimalPart = (decimalPart + '00').substring(0, 2);

  return `${integerPart}.${decimalPart}`;
};
