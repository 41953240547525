import { Button, Input, LoadingSpinner, Select } from '@vfit/shared/atoms';
import * as S from './clickToCallCardToSubmit.style';
import { organizeClickToCall } from './clickToCallCardToSubmit.organize';
import { IClickToCallToSubmit } from './clickToCallCardToSubmit.models';

export const ClickToCallCardToSubmit = ({
  contactTimes,
  clickToCallData,
  form,
  handleSubmit,
  callMeBack,
  isLoading,
}: IClickToCallToSubmit) => {
  const CMSData = organizeClickToCall(clickToCallData);
  const {
    register,
    formState: { isValid, errors },
  } = form;

  return (
    <S.ClickToCall>
      {isLoading ? (
        <S.LoadingContainer>
          <div className="loading style-2">
            <LoadingSpinner />
          </div>
        </S.LoadingContainer>
      ) : (
        <>
          <S.Title>{CMSData?.title}</S.Title>
          <S.Description
            dangerouslySetInnerHTML={{
              __html: callMeBack ? CMSData?.description : CMSData?.descriptionCallMeNow,
            }}
          />
          <section>
            <S.InputsBox callMeBack={callMeBack}>
              <Input
                error={errors?.phoneNumber?.message}
                {...register('phoneNumber')}
                label={CMSData?.phoneNumber}
              />
              {callMeBack && (
                <Select
                  className="input"
                  label={CMSData?.contactTime}
                  items={contactTimes}
                  {...register('timeSlot')}
                  error={errors?.timeSlot?.message}
                />
              )}
            </S.InputsBox>
            <Button variant="secondary" disabled={!isValid} onClick={handleSubmit}>
              {CMSData?.buttonLabel}
            </Button>
            <S.FooterNote dangerouslySetInnerHTML={{ __html: CMSData?.footer }} />
          </section>
        </>
      )}
    </S.ClickToCall>
  );
};

