import React, { useEffect, useState } from 'react';
import { Toast } from '@vfit/shared/atoms';
import { IToastDetail } from './interfaces';

export const ToastWrapper = () => {
  const initialToast: IToastDetail = {
    title: '',
    timer: 8000,
  };
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastDetail, setToastDetail] = useState<IToastDetail>(initialToast);

  if (typeof document !== 'undefined') {
    document.addEventListener('toastServiceNotificationEvent', (event) => {
      const { detail } = event as CustomEvent as { detail: IToastDetail };
      setToastDetail(detail || toastDetail);
      setShowToast(!showToast);
    });
  }

  const resetToastStatus = () => {
    setTimeout(() => {
      setToastDetail(initialToast);
      setShowToast(false);
    }, toastDetail.timer || initialToast.timer);
  };

  useEffect(() => {
    if (showToast) {
      resetToastStatus();
    }
  }, [showToast]);

  return <Toast show={showToast} text={toastDetail.title || ''} />;
};
