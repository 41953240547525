import React from 'react';
import Script from 'next/script';
import { handleEnv } from '@vfit/shared/data-access';

const YextSearch = () => {
  const apiKey = handleEnv('NX_YEXT_API_KEY');
  const businessId = handleEnv('NEXT_PUBLIC_YEXT_BUSINESS_ID');
  const experienceKey = handleEnv('NEXT_PUBLIC_YEXT_EXP_KEY');
  const experienceVersion = handleEnv('NEXT_PUBLIC_YEXT_EXP_VER');
  const redirectUrl = handleEnv('NEXT_PUBLIC_YEXT_REDIRECT_URL');
  if (
    !apiKey ||
    !businessId ||
    !experienceKey ||
    !experienceVersion ||
    process.env['NODE_ENV'] === 'development'
  )
    return null;
  return (
    <>
      <Script
        id="yextSearch"
        dangerouslySetInnerHTML={{
          __html: `
            function initAnswers() {
              ANSWERS.init({
              apiKey: "${apiKey}",
              experienceKey: "${experienceKey}",
              experienceVersion: "${experienceVersion}",
              locale: "it",
              businessId: "${businessId}",
              templateBundle: TemplateBundle.default,
              onReady: function() {
                ANSWERS.addComponent("SearchBar", {
                  container: ".search-form",
                  name: "search-bar",
                  redirectUrl: "${redirectUrl}",
                  placeholderText: "Cosa stai cercando?",
                  onCreate: function() {
                    const originalQuerySubmit = this.onQuerySubmit
                    this.onQuerySubmit = (
                      inputEl,
                      queryTrigger
                    ) => {
                      inputEl.value = inputEl.value.toLowerCase();
                      originalQuerySubmit.call(this, inputEl, queryTrigger)
                    }
                  }
              });
            }});
            };
              setTimeout(() => {
                initAnswers();
                setTimeout(() => {
                  const submit = document.getElementsByClassName('yxt-SearchBar-button');
                  if (submit) submit?.[0]?.parentNode?.removeChild(submit[0]);
                  const sharedEvent = new CustomEvent("search-bar-ready", {
                    bubbles: true,
                    detail: { isReady:true },
                  })
                    document.dispatchEvent(sharedEvent);
                }, 0);
              }, 0);
        `,
        }}
        strategy="lazyOnload"
      />
    </>
  );
};

export default YextSearch;
