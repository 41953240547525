import styled from 'styled-components';
import { breakpoints, fonts, getTypography } from '@vfit/shared/themes';

export const Container = styled.div<{ isApp?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${({ isApp }) => (isApp ? 'inherit' : '40px 44px')};
  justify-content: center;
  align-content: center;
  text-align: center;
  .capitalize-first {
    text-transform: lowercase;
  }
  .capitalize-first::first-letter {
    text-transform: uppercase;
  }
  @media (min-width: ${breakpoints.tablet}) {
    gap: 24px;
    padding: 64px 40px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    gap: 24px;
    padding: 64px 109px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.regular};
  font-weight: 800;
  ${getTypography('h4.bold')}
  margin-bottom: 8px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 300;
  margin-bottom: 32px;
  ${getTypography('h6.light')}
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const LoadingContainer = styled.div`
  position: relative;
  left: 20px;
  top: 13px;
  svg {
    width: 18px;
    height: 15px;
  }
`;
